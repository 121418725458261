import { Injectable } from '@angular/core';
import { Common } from '@icc/common';
import { TranslateService } from '@icc/common/translate.service';
import { isObject, isString } from 'util';

@Injectable()
export class PriceDictionaryService {
  constructor(
      private translateService: TranslateService
  ) {}

  locale(elems: any): string {
    let elemsString = JSON.stringify(elems);
    let re;
    if (Common.isArray(elems)) {
        for (const k in this.dictionary) {
            re = new RegExp('"' + k + '"', 'g');
            elemsString = elemsString.replace(
                re,
                '"' + this.dictionary[k as keyof PriceDictionaryService['dictionary']] + '"'
            );
            re = new RegExp('"data.' + k + '"', 'g');
            elemsString = elemsString.replace(
                re,
                '"' + this.dictionary[k as keyof PriceDictionaryService['dictionary']] + '"'
            );
        }

        return JSON.parse(elemsString);
    } else if (isObject(elems)) {
        for (const k in this.dictionary) {
            re = new RegExp('"' + k + '":', 'g');
            elemsString = elemsString.replace(
                re,
                '"' +
                    this.dictionary[k as keyof PriceDictionaryService['dictionary']].trim() +
                    '":'
            );
        }
        return JSON.parse(elemsString);
    } else if (isString(elems)) {
        if (this.dictionary.hasOwnProperty(elems)) {
            return this.dictionary[elems];
        }
    }
    return elems;
  }

  dictionary = {
    accessories: this.translateService.instant('ACCESSORY|Akcesoria'),
    additionalLockCylinder: this.translateService.instant('DOOR|Rodzaj wkładki zamka dodatkowego'),
    additionalLockPlate: this.translateService.instant('DOOR|Typ szyldu zamka dodatkowego'),
    alignment: this.translateService.instant('WINDOW|Wyrównanie'),
    all: this.translateService.instant('INTERFACE|Wszystko'),
    alushell: this.translateService.instant('WINDOW|Nakładka alum.'),
    alushellPercent: this.translateService.instant('WINDOW|Nakładka alum. - dopł. procentowa'),
    amount: this.translateService.instant('DIMENSION|Rozmiar'),
    angles: this.translateService.instant('DIMENSION|Kąty'),
    area: this.translateService.instant('DIMENSION|Pow.'),
    assembly: this.translateService.instant('CONFIGURATOR|Montaż'),
    bars: this.translateService.instant('WINDOW|Szprosy'),
    baseValue: this.translateService.instant('INTERFACE|Wartość bazowa'),
    beadsPrice: this.translateService.instant('WINDOW|Dopłata za listwy przyszybowe'),
    bottom: this.translateService.instant('INTERFACE|Dół'),
    bottomSashesCount: this.translateService.instant('CONFIGURATOR|Liczba kwater u dołu'),
    box: this.translateService.instant('ROLLERSHUTTER|Skrzynka'),
    boxPrice: this.translateService.instant('ROLLERSHUTTER|Cena skrzynki'),
    cassonetto: this.translateService.instant('GOODS|Kasonetka'),
    categoryId: this.translateService.instant('INTERFACE|Id kategorii'),
    cause: this.translateService.instant('CONFIGURATOR|Powód'),
    changedHandlesHeights: this.translateService.instant('WINDOW|Zmiany wysokości klamek'),
    handlePosition: this.translateService.instant('WINDOW|Zmiany pozycji klamek'),
    changedSize: this.translateService.instant('CONFIGURATOR|Zmiana rozmiaru'),
    charge: this.translateService.instant('CONFIGURATOR|Dopłata'),
    chargeType: this.translateService.instant('CONFIGURATOR|Rodzaj dopłaty'),
    code: this.translateService.instant('WINDOW|Kod'),
    color: this.translateService.instant('COLOR|Kolor'),
    colorAlushell: this.translateService.instant('COLOR|Kolor nakładki alum.'),
    colorField: this.translateService.instant('COLOR|Pole koloru'),
    colorGroup: this.translateService.instant('COLOR|Grupa koloru'),
    colorName: this.translateService.instant('COLOR|Nazwa koloru'),
    colorOut: this.translateService.instant('COLOR|Kolor zewn.'),
    colors: this.translateService.instant('COLOR|Kolory'),
    complementaryGoods: this.translateService.instant('GOODS|Dobra komplementarne'),
    constructionLimitations: this.translateService.instant('WINDOW|Ograniczenia konstrukcji'),
    constructSupp: this.translateService.instant('WINDOW|Rabat za wiele skrzydeł'),
    control: this.translateService.instant('GARAGE|Napęd/mechanizm otwierania garażu'),
    count: this.translateService.instant('INTERFACE|Ilość'),
    coupledWindow: this.translateService.instant('INTERFACE|Konstrukcja złożona'),
    couplings: this.translateService.instant('WINDOW|Łączniki'),
    data: this.translateService.instant('INTERFACE|Dane'),
    decorativeHingeCovers: this.translateService.instant('DOOR|Ozdobne nakładki zawiasów'),
    discountGroup: this.translateService.instant('OFFER|Grupa rabatów'),
    discountGroups: this.translateService.instant('OFFER|Grupy rabatów'),
    dividers: this.translateService.instant('WINDOW|Słupki/Poprzeczki'),
    dividersCross: this.translateService.instant('WINDOW|Łączenia szczeblin'),
    doorHandleAdjustment: this.translateService.instant('DOOR|Przystosowanie pod pochwyt'),
    doorModel: this.translateService.instant('DOOR|Model drzwi'),
    doorPortal: this.translateService.instant('DOOR|Portal'),
    portalLeftWidth: this.translateService.instant('CONFIGURATOR|Szerokość portalu lewego'),
    portalRightWidth: this.translateService.instant('CONFIGURATOR|Szerokość portalu prawego'),
    portalUpperHeight: this.translateService.instant('CONFIGURATOR|Wysokość portalu górnego'),
    portalWallThickness: this.translateService.instant('CONFIGURATOR|Szerokość muru'),
    doorSize: this.translateService.instant('DIMENSION|Rozmiar drzwi'),
    doorViewer: this.translateService.instant('DOOR|Wizjer'),
    outerDoor: this.translateService.instant('DOOR|Dopłata za drzwi otwierane na zewnątrz'),
    drive: this.translateService.instant('ROLLERSHUTTER|Sterowanie'),
    driveElems: this.translateService.instant('ACCESSORY|Akcesoria do sterowania'),
    driveManual: this.translateService.instant('ROLLERSHUTTER|Sterowanie ręczne'),
    duplex: this.translateService.instant('WINDOW|Dupleks'),
    electronicLock: this.translateService.instant('DOOR|Typ elektronicznej wkładki'),
    fabric: this.translateService.instant('AWNING|Materiał'),
    factor: this.translateService.instant('WINDOW|Współczynnik'),
    factorArea: this.translateService.instant('WINDOW|Współczynnik za pole'),
    fields: this.translateService.instant('WINDOW|Pola'),
    filling: this.translateService.instant('WINDOW|Wypełnienie'),
    fillingShape: this.translateService.instant('WINDOW|Kształt wypełnienia'),
    fitting: this.translateService.instant('WINDOW|Okucie bezp.'),
    fittings: this.translateService.instant('WINDOW|Okucia bezpieczne'),
    fittingsPercent: this.translateService.instant('WINDOW|Okucia bezpieczne (dopł. procentowa)'),
    frame: this.translateService.instant('WINDOW|Rama'),
    glass: this.translateService.instant('WINDOW|Szyba'),
    glassTypes: this.translateService.instant('WINDOW|Szyby'),
    glazingCount: this.translateService.instant('WINDOW|Liczba szyb'),
    glazingPrice: this.translateService.instant('WINDOW|Cena za pakiet szybowy'),
    good: this.translateService.instant('GOODS|Dobro'),
    handles: this.translateService.instant('WINDOW|Klamki'),
    handlesOuter: this.translateService.instant('WINDOW|Klamka zewnętrzna'),
    handlesHeights: this.translateService.instant('WINDOW|Wysokości klamek'),
    hardwareType: this.translateService.instant('DOOR|Typ osprzętu'),
    height: this.translateService.instant('DIMENSION|Wysokość'),
    hinge: this.translateService.instant('DOOR|Zawias'),
    id: this.translateService.instant('INTERFACE|ID'),
    idOuter: this.translateService.instant('INTERFACE|ID zewn.'),
    index: this.translateService.instant('INTERFACE|Indeks'),
    inscription: this.translateService.instant('AWNING|Napis na falbanie'),
    intMullions: this.translateService.instant(
        'WINDOW|Słupki/Poprzeczki/Szczebliny/Szprosy konstrukcyjne'
    ),
    isashId: this.translateService.instant('WINDOW|Nr pola'),
    kicker: this.translateService.instant('WINDOW|Kopacz'),
    left: this.translateService.instant('INTERFACE|Lewy'),
    length: this.translateService.instant('DIMENSION|Długość'),
    line: this.translateService.instant('CONFIGURATOR|Linia'),
    lock: this.translateService.instant('DOOR|Zamek'),
    lowThreshold: this.translateService.instant('WINDOW|Niski próg'),
    mainLockCylinder: this.translateService.instant('DOOR|Rodzaj wkładki zamka głównego'),
    mainLockPlate: this.translateService.instant('DOOR|Typ szyldu zamka głównego'),
    margin: this.translateService.instant('OFFER|Marża'),
    market: this.translateService.instant('OFFER|Rynek'),
    marketMargin: this.translateService.instant('OFFER|Marża rynku'),
    montage: this.translateService.instant('CONFIGURATOR|Montaż'),
    mosquitoColor: this.translateService.instant('MOSQUITO|Kolor siatki'),
    mosquitoMontage: this.translateService.instant('MOSQUITO|Montaż siatki'),
    mosquitoProfile: this.translateService.instant('MOSQUITO|Profil siatki'),
    mosquitoSize: this.translateService.instant('MOSQUITO|Rozmiar siatki'),
    mounting: this.translateService.instant('AWNING|Sposób montażu markizy'),
    multiSash: this.translateService.instant('WINDOW|Konstr. wieloskrzydłowa'),
    muntins: this.translateService.instant('WINDOW|Szprosy'),
    name: this.translateService.instant('INTERFACE|Nazwa'),
    nameOuter: this.translateService.instant('INTERFACE|Nazwa zewn.'),
    outerColorName: this.translateService.instant('COLOR|Nazwa koloru zewn.'),
    outreach: this.translateService.instant('AWNING|Wysięg'),
    overlay: this.translateService.instant('WINDOW|Nakładka'),
    overload: this.translateService.instant('ROLLERSHUTTER|Przeciążenie'),
    overRuCount: this.translateService.instant('WINDOW|Liczba skrzydeł RU'),
    oversize: this.translateService.instant('WINDOW|Ponadgabaryt'),
    panelPrice: this.translateService.instant('WINDOW|Cena panelu'),
    panelSizes: this.translateService.instant('WINDOW|Rozmiar paneli'),
    panelType: this.translateService.instant('WINDOW|Rodzaj panelu'),
    panelTypes: this.translateService.instant('WINDOW|Rodzaje panelu'),
    perimeter: this.translateService.instant('INTERFACE|Obwód'),
    plateType: this.translateService.instant('DOOR|Typ oszyldowania'),
    pleatedBlind: this.translateService.instant('PLEATEDBLIND|Plisa'),
    price: this.translateService.instant('OFFER|Cena'),
    price_per_m: this.translateService.instant('OFFER|Cena za mb'),
    price_per_q: this.translateService.instant('OFFER|Cena za szt.'),
    priceAmount: this.translateService.instant('OFFER|Cena jednostkowa'),
    priceId: this.translateService.instant('CONFIGURATOR|Id cennika'),
    priceType: this.translateService.instant('CONFIGURATOR|Typ ceny'),
    profile: this.translateService.instant('CONFIGURATOR|Profil'),
    profile_id: this.translateService.instant('CONFIGURATOR|Id profilu'),
    profiles: this.translateService.instant('CONFIGURATOR|Profile'),
    promotionRaster: this.translateService.instant('OFFER|Promocja'),
    promotionRollerShutterRaster: this.translateService.instant('OFFER|Promocja'),
    promotionFilling: this.translateService.instant('OFFER|Promocja'),
    pull: this.translateService.instant('DOOR|Typ pochwytu'),
    quantity: this.translateService.instant('INTERFACE|Liczba sztuk'),
    renoFrame: this.translateService.instant('INTERFACE|Rama renowacyjna'),
    right: this.translateService.instant('INTERFACE|Prawy'),
    rollerAssembly: this.translateService.instant('ROLLERSHUTTER|Montaż rolety'),
    rollerBoxes: this.translateService.instant('ROLLERSHUTTER|Rolety'),
    rollerBoxSize: this.translateService.instant('ROLLERSHUTTER|Rozmiar skrzynki rolety'),
    rollerShutterMosquito: this.translateService.instant(
        'ROLLERSHUTTER|Dopłata za siatkę antyinsektową'
    ),
    rollerColor: this.translateService.instant('ROLLERSHUTTER|Kolor rolety'),
    rollerColors: this.translateService.instant('ROLLERSHUTTER|Kolory rolety'),
    colorGroupId: this.translateService.instant('COLOR|ID grupy kolorów'),
    element: this.translateService.instant('INTERFACE|Element'),
    rollerColorsPercent: this.translateService.instant('ROLLERSHUTTER|Dopłata procentowa za kolor rolety'),
    rollerConsole: this.translateService.instant('ROLLERSHUTTER|Konsola rolety'),
    rollerDrive: this.translateService.instant('ROLLERSHUTTER|Sterowanie rolety'),
    rollerDriveHanger: this.translateService.instant('ROLLERSHUTTER|Wieszak rolety'),
    rollerDriveElems: this.translateService.instant(
        'ROLLERSHUTTER|Dodatki do sterowania elektrycznego'
    ),
    rollerDriveManual: this.translateService.instant(
        'ROLLERSHUTTER|Dodatki do sterowania ręcznego'
    ),
    rollerProfile: this.translateService.instant('ROLLERSHUTTER|Typ pancerza'),
    rollers: this.translateService.instant('ROLLERSHUTTER|Rolety'),
    rollerShutterGuideRail: this.translateService.instant('ROLLERSHUTTER|Prowadnice'),
    rollerShutterSlat: this.translateService.instant('ROLLERSHUTTER|Listwa końcowa'),
    rollerSlatDivision: this.translateService.instant('ROLLERSHUTTER|Dopłata za podział pancerza'),
    rollerGrayPolystyrene: this.translateService.instant('ROLLERSHUTTER|Szary styropian'),
    rollUpPrice: this.translateService.instant('AWNING|Cena falbany zwijanej'),
    sashAccessories: this.translateService.instant('ACCESSORY|Akcesoria do kwater'),
    sashes: this.translateService.instant('WINDOW|Kwatery'),
    sashesHardware: this.translateService.instant('ACCESSORY|Akcesoria do kwater'),
    sashFrames: this.translateService.instant('WINDOW|Skrzydła'),
    sashId: this.translateService.instant('WINDOW|Nr kwatery'),
    sashShape: this.translateService.instant('WINDOW|Kształt kwatery'),
    sashType: this.translateService.instant('WINDOW|Typ kwatery'),
    sealColor: this.translateService.instant('WINDOW|Kolor uszczelki'),
    sideProfile: this.translateService.instant('WINDOW|Poszerzenie'),
    siliconeColor: this.translateService.instant('WINDOW|Kolor silikonu'),
    shape: this.translateService.instant('CONFIGURATOR|Kształt'),
    side: this.translateService.instant('INTERFACE|Bok'),
    sideAccessories: this.translateService.instant('ACCESSORY|Akcesoria do krawędzi'),
    size: this.translateService.instant('DIMENSION|Rozmiar'),
    steel: this.translateService.instant('WINDOW|Rodzaj stali'),
    sticked: this.translateService.instant('WINDOW|Naklejane'),
    suppId: this.translateService.instant('CONFIGURATOR|Id dopłaty'),
    system: this.translateService.instant('CONFIGURATOR|System'),
    systemFactors: this.translateService.instant('CONFIGURATOR|Dopłaty procentowe'),
    text_price: this.translateService.instant('AWNING|Cena za tekst'),
    to: this.translateService.instant('INTERFACE|Do'),
    top: this.translateService.instant('INTERFACE|Góra'),
    type: this.translateService.instant('INTERFACE|Rodzaj'),
    typeId: this.translateService.instant('INTERFACE|Id rodzaju'),
    typeName: this.translateService.instant('INTERFACE|Rodzaj'),
    typeCode: this.translateService.instant('INTERFACE|Kod rodzaju'),
    types: this.translateService.instant('INTERFACE|Rodzaje'),
    typesSymbol: this.translateService.instant('INTERFACE|Rodzaje (symbole)'),
    u: this.translateService.instant('INTERFACE|U'),
    valance: this.translateService.instant('AWNING|Falbana'),
    value: this.translateService.instant('INTERFACE|Wartość'),
    valueType: this.translateService.instant('INTERFACE|Rodzaj wartości'),
    warmEdge: this.translateService.instant('WINDOW|Ciepła ramka'),
    width: this.translateService.instant('DIMENSION|Szerokość'),
    widthOuter: this.translateService.instant('DIMENSION|Szerokość zewn.'),
    windowsill: this.translateService.instant('ACCESSORY|Parapet'),
    wood: this.translateService.instant('CONFIGURATOR|Drewno'),
    commodityMultiplier: this.translateService.instant('CONFIGURATOR|Towary'),
    systemMultiplier: this.translateService.instant('CONFIGURATOR|System'),
    supplementMultiplier: this.translateService.instant('CONFIGURATOR|Dopłaty'),
    rollerMultiplier: this.translateService.instant('CONFIGURATOR|Roleta'),
    mosquitoMultiplier: this.translateService.instant('CONFIGURATOR|Siatka'),
    sashDependencies: this.translateService.instant('CONFIGURATOR|Zależności dla skrzydła'),
    dependencies: this.translateService.instant('CONFIGURATOR|Zależności dla konstrukcji'),
    keyForMainLockCylinder: this.translateService.instant(
        'CONFIGURATOR|Cena za dodatkowe klucze do zamka głównego'
    ),
    keyForAdditionalLockCylinder: this.translateService.instant(
        'CONFIGURATOR|Cena za dodatkowe klucze do zamka dodatkowego'
    ),
    casing: this.translateService.instant('CONFIGURATOR|Obejma'),
    casingName: this.translateService.instant('CONFIGURATOR|Nazwa obejmy'),
    casingInnerWidth: this.translateService.instant('CONFIGURATOR|Wewnętrzna szerokość obejmy'),
    casingWallOverlap: this.translateService.instant('CONFIGURATOR|Wyłożenie na mur'),
    lipping: this.translateService.instant('CONFIGURATOR|Listwa mocująca'),
    reversing: this.translateService.instant('PROFILE|Profil odwracający'),
};

}
