import { Common } from '../Common';
import { Configuration } from './Configuration';
import { WindowActiveConfiguration } from './WindowActiveConfiguration';
import { WindowSmallConfiguration } from './WindowSmallConfiguration';
import { PriceSegment, DiscountGroups, PricePart } from '@icc/price/b2b';
import { TimeLimitSegment } from '@icc/common/time-limit/time-limit-functions';
import { core } from '../helpers';
import {
    Fitting,
    HandlesType,
    Hinge,
    Layout,
    Lock,
    ProfileSet,
    Shape,
    System,
    WindowParameters,
    SideProfile,
    SashesType,
    Measurement,
    FrameV2,
    HandleV2,
    Sash,
    MullionV2,
    AlignmentV2,
    Coupling,
    MuntinsConfig,
    LockV2,
    FittingV2,
    SystemV2,
    ProfileV2,
    ProfileShapeV2,
    SashTypeV2,
    ReinforcementV2,
    GlazingBeadProfile,
    DoorHardwareV2,
    DoorSizes,
    SystemPacket,
    DoorSize,
    DoorLightsSize,
    Casing,
    FillingsCategories,
    DoorPanelTrim
} from '@icc/window';

import { MosquitoConfiguration } from './MosquitoConfiguration';
import { RollerShutter } from './parts/roller_shutter';

import {
    Accessory,
} from './parts/common';

import type {
    GlazingSpacer,
    Wood,
    FillingActive,
    GlazingSpacerV2,
    Filling,
} from './parts/common';
import {
    changesInVersion4,
    changesInVersion5,
    changesInVersion6,
    changesInVersion8,
    changesInVersion9,
    changesInVersion10,
    muntinsConversion,
    changesInVersion7,
    changesInVersion11,
    changesInVersion13,
    changesInVersion14,
    changesInVersion15,
    changesInVersion16,
    changesInVersion17,
    changesInVersion18,
    changesInVersion19
} from './converters/window';
import { BaseColor, Color, ColorId, ConstructionColor, ConstructionColorId, WindowColors } from './parts/common/Colors';
import { HingeV2 } from 'libs/window/src/lib/types/Hinge';
import { WoodV2 } from './parts/common/Wood';
import { ActiveMullion } from '../layout/active-mullion';
import { ActiveSash } from '../layout/active-sash';
import { ConfiguratorsDataService } from '../configurators/configurators-data.service';
import { IccSashType, HardwareVisualization, DoorPortal } from '../data-types';
import { iccColorToColorId } from './converters/window/colors';
import { AppConfig } from '../app-config';
import { Occurrence } from '@icc/legacy/dependencies/occurrence.interface';
import { Issue } from '@icc/helpers';

export class WindowConfiguration implements Configuration {
    //#region pola interfejsu
    /**
     * Wersja interfejsu
     */
    $version = 19;
    /**
     * Typ
     */
    type: 'window' | 'door' | 'hs' | 'folding_door' | 'sliding_door' = 'window';

    /**
     * Nazwa konstrukcji
     */
    name = '';
    /**
     * Cena netto konstrukcji
     */
    price: number | null = null;
    listPrice: number | null = null;
    /**
     * Cena netto konstrukcji bez marży
     */
    priceNoMargin: number | null = null;
    listPriceNoMargin: number | null = null;
    /**
     * Cena netto konstrukcji po rabatach
     */
    priceAfterDiscountsInCurrency: number | null = null;
    listPriceAfterDiscountsInCurrency: number | null = null;
    priceBeforePromotions: number | null = null;
    listPriceBeforePromotions: number | null = null;
    /** Cena brutto konstrukcji */
    priceGross: number | null = null;
    listPriceGross: number | null = null;
    /** Cena brutto konstrukcji po rabatach */
    priceGrossAfterDiscounts: number | null = null;
    listPriceGrossAfterDiscounts: number | null = null;
    priceGrossBeforePromotions: number | null = null;
    listPriceGrossBeforePromotions: number | null = null;
    /**
     * Liczba sztuk
     */
    quantity = 1;
    priceSegments: PriceSegment[] = [];
    listPriceSegments: PriceSegment[] = [];
    priceSegmentsNoMargin: PriceSegment[] = [];
    listPriceSegmentsNoMargin: PriceSegment[] = [];
    priceSegmentsBeforePromotions: PriceSegment[] = [];
    listPriceSegmentsBeforePromotions: PriceSegment[] = [];
    priceParts: PricePart[] = [];
    listPriceParts: PricePart[] = [];
    pricePartsNoMargin: PricePart[] = [];
    listPricePartsNoMargin: PricePart[] = [];
    pricePartsBeforePromotions: PricePart[] = [];
    listPricePartsBeforePromotions: PricePart[] = [];
    discountGroups: DiscountGroups = {};
    discountGroupsNoMargin: DiscountGroups = {};
    timeLimit: number | null = null;
    timeLimitsStack: TimeLimitSegment[] = [];
    title = '';
    description = '';
    attachments: any[] = [];
    deletedAttachments: any[] = [];
    /**
     * Poprawność składowych konstrukcji
     */
    valid: {
        system: null | boolean;
        shape: null | boolean;
        dimensions: null | boolean;
        sashes: null | boolean;
        sealColor: null | boolean;
        siliconeColor: null | boolean;
        colors: null | boolean;
        wood: null | boolean;
        profileSet: null | boolean;
        loadedMuntinsColors: null | boolean;
        loadedProfiles: null | boolean;
        mullionsProfiles: null | boolean;
        sashesProfiles: null | boolean;
        frameProfiles: null | boolean;
        loadedGlazingBeads: null | boolean;
        loadedFillings: null | boolean;
        glazingBeads: null | boolean;
        fillings: null | boolean;
        extensionsColors: null | boolean;
        extensionsSystems: null | boolean;
        muntins: null | boolean;
        muntinsColors: null | boolean;
        loadedThresholdColor: null | boolean;
        thresholdColor: null | boolean;
        loadedLippingColor: null | boolean;
        lippingColor: null | boolean;
        loadedFillingsColors: boolean;
        innerLippingColor: null | boolean;
        casing: null | boolean;
        fillingsCategories: null | boolean;
        System: null | boolean;
        panelGlazings: null | boolean;
        panelTrims: null | boolean;
    } = {
        system: null,
        shape: null,
        dimensions: null,
        sashes: null,
        sealColor: null,
        siliconeColor: null,
        colors: null,
        wood: null,
        profileSet: null,
        loadedMuntinsColors: null,
        loadedFillingsColors: false,
        loadedProfiles: null,
        mullionsProfiles: null,
        sashesProfiles: null,
        frameProfiles: null,
        loadedGlazingBeads: null,
        loadedFillings: null,
        glazingBeads: null,
        fillings: null,
        extensionsColors: null,
        extensionsSystems: null,
        muntins: null,
        muntinsColors: null,
        loadedThresholdColor: null,
        thresholdColor: null,
        loadedLippingColor: null,
        lippingColor: null,
        innerLippingColor: null,
        casing: null,
        fillingsCategories: null,
        System: null,
        panelGlazings: null,
        panelTrims: null,
    };

    /**
     * Słownik z danymi składowych konfiguracji
     */
    dictionary: {
        /**
         * Kolory
         */
        colors: Record<Color['id'], Color>;
        /**
         * Kolor konstrukcji
         */
        constrColors: Record<ConstructionColor['id'], ConstructionColor>;
        /**
         * Kolory uszczelek
         */
        sealColors: Record<BaseColor['id'], BaseColor>;
        /**
         * Kolory silikonów
         */
        siliconeColors: Record<BaseColor['id'], BaseColor>;
        /**
         * Kolory dodatków (klamek, zawiasów)
         */
        accessoryColors: Record<BaseColor['id'], BaseColor>;
        /**
         * Rodzaj drewna
         */
        woods: Record<WoodV2['id'], WoodV2>;
        /**
         * Ciepła ramka
         */
        glazingSpacers: Record<GlazingSpacerV2['id'], GlazingSpacerV2>;
        /**
         * Zawias
         */
        hinges: Record<HingeV2['id'], HingeV2>;
        /**
         * Zamek
         */
        locks: Record<LockV2['id'], LockV2>;
        /**
         * Zestaw okuciowy
         */
        fittings: Record<FittingV2['id'], FittingV2>;
        /**
         * Linia produktowa
         */
        systems: Record<SystemV2['id'], SystemV2>;
        /**
         * Profile
         */
        profiles: Record<ProfileV2['id'], ProfileV2>;
        /**
         * Kształty profili
         */
        profileShapes: Record<ProfileShapeV2['id'], ProfileShapeV2>;
        /**
         * Funkcje skrzydeł (kierunki otwierania)
         */
        sashTypes: Record<SashTypeV2['id'], SashTypeV2>;
        /**
         * Wzmocnienia
         */
        reinforcements: Record<ReinforcementV2['id'], ReinforcementV2>;
        /**
         * Klamki
         */
        handles: Record<HandleV2['id'], HandleV2>;
        /**
         * Wypełnienia (pakiety szybowe, panele)
         */
        fillings: Record<Filling['id'], Filling>;
        /**
         * Osprzęt drzwi
         */
        doorHardware: Record<DoorHardwareV2['id'], DoorHardwareV2>;
        /**
         * Systemy akcesoriów
         */
        hardwareSystems: Record<DoorHardwareV2['id'], DoorHardwareV2>;
        /**
         * Rozmiary drzwi
         */
        doorSizes: Record<DoorSize['id'], DoorSize>;
        /**
         * Rozmiary doświetli drzwi
         */
        doorLightSizes: Record<DoorLightsSize['id'], DoorLightsSize>;
        /**
         * Listwy dekoracyjne
         */
        doorPanelTrims: Record<DoorPanelTrim['id'], DoorPanelTrim>;
    } = {
        colors: {},
        constrColors: {},
        sealColors: {},
        siliconeColors: {},
        accessoryColors: {},
        woods: {},
        glazingSpacers: {},
        hinges: {},
        locks: {},
        fittings: {},
        systems: {},
        profiles: {},
        profileShapes: {},
        sashTypes: {},
        reinforcements: {},
        handles: {},
        fillings: {},
        doorHardware: {},
        hardwareSystems: {},
        doorSizes: {},
        doorLightSizes: {},
        doorPanelTrims: {},
    };

    isMistakeProduct = false;
    hasWarranty = true;

    /**
     * Pomiary
     */
    measurements: Measurement[] = [];
    //#endregion

    //#region kształt, rozmiar, współczynniki
    /**
     * Wysokość konstrukcji
     */
    height = 1500;
    /**
     * Szerokość konstrukcji
     */
    width = 1500;
    /**
     * Kształt konstrukcji
     */
    shape: Shape = {
        shape: 'rect',
        width: 1500,
        height: 1500,
        circuit: 6000,
        s1: 1500,
        s2: 0,
        s3: 0,
        h1: 1500,
        h2: 0,
        h3: 1500,
    };
    /**
     * Rozmiary drzwi
     */
    doorSizes: DoorSizes = {
        sashSize: 0,
        sashSizeId: null,
        passiveSashSize: 0,
        passiveSashSizeId: null,
        shortening: 0,
        frameShortening: 0,
        topLightSize: 0,
        topLightSizeId: null,
        leftLightSize: 0,
        leftLightSizeId: null,
        rightLightSize: 0,
        rightLightSizeId: null,
        customWidth: 0,
        customHeight: 0,
        customSize: false,
        sizeIntervalCustomWidth: 0,
        sizeIntervalHeight: 0,
        standardHeight: null,
        isStandardHeightSelected: null,
        isStandardWidthSelected: null,
        shorteningAvailable: null,
    };

    /**
     * Parametry konstrukcji
     */
    parameters: WindowParameters = {
        weight: null,
    };
    //#endregion

    //#region kolory, drewno, wykończenie
    /**
     * Kolorystyka konstrukcji
     */
    colors: WindowColors = {
        frame: {
            outer: null,
            inner: null,
            core: null,
            alushell: null,
        },
        sash: {
            outer: null,
            inner: null,
            core: null,
            alushell: null,
        },
    };
    /**
     * Rozszerzony zestaw kolorów konstrukcji
     */
    colorsSashExt = false;
    /**
     * Id rodzaju drewna
     */
    wood: Wood['id'] | null = null;
    /**
     * Sposób obróbki nakładki alumiowej
     */
    alushellType: 'painted' | 'brushed' | null = null;
    /**
     * Id koloru uszczelki
     */
    sealColor: ColorId = null;
    /**
     * Id koloru silikonu
     */
    siliconeColor: ColorId = null;
    /**
     * Kolory progu
     */
    thresholdColor: ColorId = null;
    /**
     * Kolor listwy mocującej
     */
    lippingColor: ColorId = null;
    innerLippingColor: ColorId = null;
    lipping: number | null = null;

    //#endregion

    //#region wypełnienia
    /**
     * Id typu ciepłej ramki
     */
    glazingBeadType: boolean | number = false;
    /**
     * Id ciepłej ramki
     */
    glazingSpacer: GlazingSpacer['id'] = null;
    /**
     * Pakiety szybowe zdefiniowane przez użytkownika
     */
    customFillings: FillingActive[] = [];
    /**
     * Czy pakiet szybowy jest objęty gwarancją.
     */
    hasGlazingWarranty = true;
    //#endregion

    //#region akcesoria
    /**
     * Dodatki do konstrukcji
     */
    accessories: Accessory[] = [];
    /**
     * Dodatki zablokowane dla danej konstrukcji
     */
    BlockedAccessories: number[] = [];
    /**
     * Dodatki do krawędzi konstrukcji
     */
    sideAccessories: {
        /**
         * Dodatki do górnej krawędzi konstrukcji
         */
        top: Accessory[];
        /**
         * Dodatki do dolnej krawędzi konstrukcji
         */
        bottom: Accessory[];
        /**
         * Dodatki do lewej krawędzi konstrukcji
         */
        left: Accessory[];
        /**
         * Dodatki do prawej krawędzi konstrukcji
         */
        right: Accessory[];
    } = {
        top: [],
        bottom: [],
        left: [],
        right: [],
    };
    /**
     * Portal drzwiowy
     */
    doorPortal: DoorPortal | null = null;
    //#endregion

    //#region okucia
    /**
     * Czy kostrukcja ma klamkę/pochwyt.
     */
    hasHandle = false;
    /**
     * Typ pochwytu/klamki
     */
    handleType: HandlesType = 'InnerLever';
    /**
     * Id zestawu zawiasowego
     */
    hinge: {
        id: Hinge['id'];
        color?: ColorId;
        visualizations: HardwareVisualization[] | null;
    } = null;
    /**
     * Id zamku
     */
    lock: Lock['id'] = null;
    /**
     * Id zestawu okuciowego
     */
    fitting: Fitting['id'] = null;
    //#endregion

    //#region profile, wzmocnienia, system
    /**
     * Id linii produktowej
     */
    system: System['id'] | null = null;

    /**
     * Pakiety systemów
     */
    systemPackets: SystemPacket['id'][] = [];

    /** Stal otwarta/zamknięta */
    steel: 'Closed' | 'Opened' | null = null;
    /**
     * Czy konstrukcja z nakładką aluminiową.
     */
    hasAlushell = false;
    /**
     * Zestaw domyślnych profili
     */
    profileSet: ProfileSet = {
        id: null,
        name: '',
        frameTop: null,
        frameBottom: null,
        frameSide: null,
        sash: null,
        fixedMullion: null,
        falseMullion: null,
        threshold: null,
        sashOutward: null,
        centralHandleFalseMullion: null,
        doubleHandleFalseMullion: null,
        zMullion: null,
        glazingBeadShape: null,
        systems: [],
    };
    /**
     * Kawałki profili
     */
    usedProfilesSegments: {
        [profileId: number]: number[];
    } = {};

    /**
     * Wykońćzenie zgrzewu
     */
    weldFinishType: 'V' | 'thin' = 'V';
    /**
     * Położenie odpływu
     */
    drainagePosition: 'bottom' | 'front' = 'front';

    /**
     * Id niskiego progu (jeśli zastosowano)
     */
    lowThreshold: false | number = false;
    //#endregion

    //#region konstrukcja
    /**
     * Ramy
     */
    frames: FrameV2[] = [];
    /**
     * Kwatery
     */
    sashes: Sash[] = [];
    /**
     * Słupki/poprzeczki
     */
    mullions: MullionV2[];
    /**
     * Wyrównania
     */
    alignments: AlignmentV2[];
    /**
     * Łączniki
     */
    couplings: Coupling[] = [];
    /**
     * Profile boczne (poszerzenia, listwy przyparapetowe, itp.)
     */
    sideProfiles: SideProfile[] = [];

    rollerShutter: RollerShutter = null;
    mosquito: MosquitoConfiguration = null;

    sashesType: SashesType = 'Fix';
    ownedSashesTypes = {
        window: true,
        doorActive: false,
        doorPassive: false,
        doorTopLight: false,
        doorLeftLight: false,
        doorRightLight: false,
    };

    /**
     * Czy konstrukcja balkonowa
     */
    balcony = false;
    // kicker = false;
    /**
     * Został ustawiony profil podwaliny
     */
    foundationProfile = false;
    /**
     * Układ konstrukcji
     */
    layout: Layout | null = null;
    /**
     * Opcje szprosów
     */
    muntins: MuntinsConfig = null;
    //#endregion

    //#region one
    oneGlazing = false;
    oneFilling: {
        window: string | false;
        defaultWindow: string | false;
        doorActive: string | false;
        doorActiveInner: string | false;
        doorPassive: string | false;
        doorPassiveInner: string | false;
        doorLight: string | false;
        doorTopLight: string | false;
        doorLeftLight: string | false;
        doorRightLight: string | false;
    } = {
        window: false,
        defaultWindow: false,
        doorActive: false,
        doorActiveInner: false,
        doorPassive: false,
        doorPassiveInner: false,
        doorLight: false,
        doorTopLight: false,
        doorLeftLight: false,
        doorRightLight: false,
    };
    oneGlazingBead = false;
    oneGlazingBeadSash: {
        fix: boolean | number;
        sashes: boolean | number;
    } = {
        fix: false,
        sashes: false,
    };
    oneHandle = false;
    oneHandlesHeight = false;
    oneReinforcement = true;
    oneBondedGlazing = false;

    attributes: {
        id: string;
        name: string;
        value: string;
        valueId?: number;
    }[] = [];
    //#endregion

    casing: Casing = {
        id: null,
        name: null,
        code: null,
        innerWidth: null,
        wallOverlap: null,
        isCasingSelected: null
    };

    fillingsCategories: FillingsCategories = {
        id: null,
        name: null
    };

    System: any = {};
    dependenciesOccurrence: Occurrence[] = [];
    offerInfo: any = [];
    issues: Issue[] = [];
    hasSeparateRoller?: boolean;
    static converters = {
        4: changesInVersion4,
        5: changesInVersion5,
        6: changesInVersion6,
        7: changesInVersion7,
        8: changesInVersion8,
        9: changesInVersion9,
        10: changesInVersion10,
        11: changesInVersion11,
        13: changesInVersion13,
        14: changesInVersion14,
        15: changesInVersion15,
        16: changesInVersion16,
        17: changesInVersion17,
        18: changesInVersion18,
        19: changesInVersion19,
    };

    // eslint-disable-next-line max-statements
    constructor(
        configuration?,
        dataRequiredToUpdate?: any,
        conversion = true,
        protected configuratorsDataService?: ConfiguratorsDataService,
        protected colorsDefaultsService?,
        private langCode = configuration?.langCode || 'pl',
        protected config: AppConfig = null
    ) {
        if (WindowConfiguration.is(configuration) && conversion) {
            this.runConversion(
                configuration,
                Object.assign(dataRequiredToUpdate, {
                    langCode: this.langCode,
                })
            );
        } else {
            if (WindowActiveConfiguration.is(configuration)) {
                this.dependenciesOccurrence = configuration.DependenciesOccurrence;
                this.name = configuration.Name;
                this.System = configuration.System;
                this.price = configuration.Price;
                this.timeLimit = configuration.timeLimit;
                this.timeLimitsStack = configuration.timeLimitsStack;
                this.priceNoMargin = configuration.PriceNoMargin;
                this.listPriceNoMargin = configuration.ListPriceNoMargin;
                this.priceAfterDiscountsInCurrency = configuration.PriceAfterDiscountsInCurrency || configuration.Price;
                this.listPriceAfterDiscountsInCurrency = configuration.ListPriceAfterDiscountsInCurrency || configuration.ListPrice;
                this.priceGross = configuration.PriceGross || configuration.Price;
                this.listPriceGross = configuration.ListPriceGross || configuration.ListPrice;
                this.priceGrossAfterDiscounts = configuration.PriceGrossAfterDiscounts || configuration.Price;
                this.listPriceGrossAfterDiscounts = configuration.ListPriceGrossAfterDiscounts || configuration.ListPrice;
                this.priceSegments = configuration.PriceSegments;
                this.listPriceSegments = configuration.ListPriceSegments;
                this.priceSegmentsNoMargin = configuration.PriceSegmentsNoMargin;
                this.listPriceSegmentsNoMargin = configuration.ListPriceSegmentsNoMargin;
                this.priceSegmentsBeforePromotions = configuration.PriceSegmentsBeforePromotions;
                this.listPriceSegmentsBeforePromotions = configuration.ListPriceSegmentsBeforePromotions;
                this.priceParts = configuration.PriceParts;
                this.listPriceParts = configuration.ListPriceParts;
                this.pricePartsNoMargin = configuration.PricePartsNoMargin;
                this.listPricePartsNoMargin = configuration.ListPricePartsNoMargin;
                this.pricePartsBeforePromotions = configuration.PricePartsBeforePromotions;
                this.listPricePartsBeforePromotions = configuration.ListPricePartsBeforePromotions;
                this.discountGroups = configuration.DiscountGroups;
                this.discountGroupsNoMargin = configuration.DiscountGroupsNoMargin;
                this.title = config?.userDescription || configuration.Title;
                this.quantity = config?.quantity || configuration.Quantity || 1;
                this.description = configuration.Description;
                this.attachments = configuration.Attachments;
                this.valid = configuration.valid;
                this.attributes = configuration.attributes;
                this.casing = configuration.Casing;

                this.height = configuration.Height;
                this.width = configuration.Width;
                this.dictionary.systems[Number(configuration.System.id)] = {
                    id: Number(configuration.System.id),
                    imageSideOnOffer: configuration.System.image_side_on_offer,
                    name: configuration.System.name,
                    titleImage: configuration.System.title_image,
                    type: configuration.System.type,
                    offerInfo: configuration.System.offer_info,
                    frameType: configuration.System.type_frame,
                    confType: configuration.System.confType,
                    doorType: configuration.System.door_type,
                    frameJointAngles: configuration.System.frame_joint_angles,
                    sashJointAngles: configuration.System.sash_joint_angles,
                    thresholdJointAngles: configuration.System.threshold_joint_angles,
                    glazingBeadJointAngles: configuration.System.glazing_bead_joint_angles,
                    availableThresholdSashRebate: configuration.System.available_threshold_sash_rebate,
                    indexNo: configuration.System.index_no,
                    windowLineGroupId: configuration.System.window_line_group_id,
                    seperateFrameAndSashShortening: configuration.System.seperate_frame_and_sash_shortening
                };
                this.system = Number(configuration.System.id);
                this.systemPackets = configuration.systemPackets;
                this.fillingsCategories = configuration.FillingsCategories;
                this.issues = configuration.Issues;

                (['frame', 'sash'] as const).forEach(part => {
                    (['outer', 'inner'] as const).forEach(side => {
                        if (configuration.Colors[part][side]?.id) {
                            this.dictionary.constrColors[
                                Number(configuration.Colors[part][side].id)
                            ] = {
                                ...configuration.Colors[part][side],
                                name: {
                                    [this.langCode]: configuration.Colors[part][side].name,
                                },
                            };
                            this.colors[part][side] = {
                                id: Number(configuration.Colors[part][side].id),
                                isDefault: configuration.Colors[part][side].isDefault,
                            };
                        }
                    });
                    (['core', 'alushell'] as const).forEach(side => {
                        if (configuration.Colors[part][side]?.id) {
                            this.dictionary.colors[
                                Number(configuration.Colors[part][side].id)
                            ] = {
                                id: Number(configuration.Colors[part][side].id),
                                name: {
                                    [this.langCode]: configuration.Colors[part][side].name,
                                },
                                code: configuration.Colors[part][side].code,
                                color: configuration.Colors[part][side].color,
                                colorImg: configuration.Colors[part][side].colorImg,
                                woodTypeId: +configuration.Colors[part][side].woodTypeId,
                                colorsPaletteId: +configuration.Colors[part][side].colorsPaletteId,
                                visualization: configuration.Colors[part][side].visualization,
                                isCore: configuration.Colors[part][side].isCore,
                                isRal: configuration.Colors[part][side].isRal,
                                colorGroups: configuration.Colors[part][side].colorGroups,
                                constructColors: configuration.Colors[part][side].constructColors,
                                coreColors: configuration.Colors[part][side].coreColors,
                                systems: configuration.Colors[part][side].systems
                            };
                            this.colors[part][side] = {
                                id: Number(configuration.Colors[part][side].id),
                                isDefault: configuration.Colors[part][side].isDefault,
                            };
                        }
                    });
                });

                this.colorsSashExt = configuration.ColorsSashExt;
                this.steel = configuration.Steel;
                if (configuration.Wood && configuration.Wood.id) {
                    this.dictionary.woods[Number(configuration.Wood.id)] = {
                        id: Number(configuration.Wood.id),
                        name: {
                            [this.langCode]: configuration.Wood.name,
                        },
                    };
                    this.wood = Number(configuration.Wood.id);
                }
                this.hasAlushell = configuration.HasAlushell;
                if (this.hasAlushell) {
                    this.alushellType = configuration.AlushellType;
                }
                this.shape = {
                    shape: configuration.Shape.shape,
                    circuit: configuration.Shape.circuit,
                };
                (['width', 'height', 's1', 's2', 's3', 'h1', 'h2', 'h3', 'd', 'radius'] as const).forEach(
                    field => {
                        if (configuration.Shape[field] != null) {
                            this.shape[field] = Number(configuration.Shape[field]);
                        }
                    }
                );
                if (configuration.Shape.type != null) {
                    this.shape.type = configuration.Shape.type;
                }
                if (configuration.Shape.arcType != null) {
                    this.shape.arcType = configuration.Shape.arcType;
                }
                this.sashes = configuration.Sashes.map<Sash>(sash =>
                    this.mapSash(sash, sash, configuration)
                );
                this.sashesType = configuration.SashesType;
                this.ownedSashesTypes = {
                    window: configuration.OwnedSashesTypes.window,
                    doorActive: configuration.OwnedSashesTypes.doorActive,
                    doorPassive: configuration.OwnedSashesTypes.doorPassive,
                    doorTopLight: configuration.OwnedSashesTypes.doorTopLight,
                    doorLeftLight: configuration.OwnedSashesTypes.doorLeftLight,
                    doorRightLight: configuration.OwnedSashesTypes.doorRightLight,
                };
                this.sideProfiles = configuration.SideProfiles.map(c => {
                    if (c.reinforcement) {
                        this.dictionary.reinforcements[Number(c.reinforcement.id)] = {
                            id: Number(c.reinforcement.id),
                            name: {
                                [this.langCode]: c.reinforcement.name,
                            },
                            price: c.reinforcement.price,
                        };
                    }
                    const dimensions = {
                        width: 0,
                        height: 0,
                    };
                    if (configuration.drawData) {
                        const extensionDimensions = configuration.drawData.extension.find(
                            el => el.extensionId === c.id
                        );
                        if (extensionDimensions) {
                            dimensions.width = extensionDimensions.rect.width;
                            dimensions.height = extensionDimensions.rect.height;
                        }
                    }
                    if (c.color.outer && !this.dictionary.constrColors[c.color.outer.id]) {
                        this.dictionary.constrColors[c.color.outer.id] = {
                            ...c.color.outer,
                            name: {
                                [this.langCode]: c.color.outer.name,
                            },
                        };
                    }
                    if (c.color.inner && !this.dictionary.constrColors[c.color.inner.id]) {
                        this.dictionary.constrColors[c.color.inner.id] = {
                            ...c.color.inner,
                            name: {
                                [this.langCode]: c.color.inner.name,
                            },
                        };
                    }
                    if (c.color.core && !this.dictionary.colors[c.color.core.id]) {
                        this.dictionary.colors[c.color.core.id] = {
                            ...c.color.core,
                            name: {
                                [this.langCode]: c.color.core.name,
                            },
                        };
                    }
                    if (c.color.alushell && !this.dictionary.colors[c.color.alushell.id]) {
                        this.dictionary.colors[c.color.alushell.id] = {
                            ...c.color.alushell,
                            name: {
                                [this.langCode]: c.color.alushell.name,
                            },
                        };
                    }
                    return {
                        ...c,
                        reinforcement: (c.reinforcement && c.reinforcement.id) || null,
                        dimensions,
                        color: {
                            inner: iccColorToColorId(c.color.inner),
                            outer: iccColorToColorId(c.color.outer),
                            core: iccColorToColorId(c.color.core),
                            alushell: iccColorToColorId(c.color.alushell),
                        }
                    };
                });
                this.alignments = configuration.Alignments;
                this.mullions = configuration.Mullions.map<MullionV2>(this.mapMullion.bind(this));
                this.dictionary.profiles = configuration.UsedProfiles.map(profile => ({
                    ...profile,
                    name: {
                        [this.langCode]: profile.name,
                    },
                })).reduce<Record<string, ProfileV2>>((prev, cur) => {
                    prev[cur.id] = cur;
                    return prev;
                }, {});
                this.dictionary.profileShapes = configuration.UsedProfileShapes.map(
                    profileShape => ({
                        ...profileShape,
                        name: {
                            [this.langCode]: profileShape.name,
                        },
                    })
                ).reduce<Record<string, ProfileShapeV2>>((prev, cur) => {
                    prev[cur.id] = cur;
                    return prev;
                }, {});
                this.usedProfilesSegments = configuration.UsedProfilesSegments;
                this.customFillings = configuration.CustomFillings;
                this.profileSet = configuration.ProfileSet;
                this.balcony = configuration.Balcony;
                this.foundationProfile = configuration.foundationProfile;

                if (configuration.Layout) {
                    this.layout = {
                        id: Number(configuration.Layout.id),
                        name: configuration.Layout.name,
                        conf: 'window',
                        changed:
                            configuration.Layout.divs && configuration.Layout.sashes
                                ? configuration.Layout.changed
                                : true,
                        sashes: configuration.Layout.sashes,
                        divs: configuration.Layout.divs,
                        sashTypes: configuration.Layout.sashTypes,
                        fromCode: false,
                        layoutCode: null,
                        countSashes: 0,
                        shape: 0,
                        transomsOnSeperateFrames: false,
                        isMirrorLayoutAvailable: configuration.Layout.isMirrorLayoutAvailable,
                        equalDivision: configuration.Layout.equalDivision,
                    };
                }
                this.oneGlazing = configuration.OneGlazing;
                this.oneFilling = {
                    window: configuration.OneFilling.window,
                    defaultWindow: configuration.OneFilling.defaultWindow,
                    doorActive: configuration.OneFilling.doorActive,
                    doorActiveInner: configuration.OneFilling.doorActiveInner,
                    doorPassive: configuration.OneFilling.doorPassive,
                    doorPassiveInner: configuration.OneFilling.doorPassiveInner,
                    doorLight: configuration.OneFilling.doorLight,
                    doorTopLight: configuration.OneFilling.doorTopLight,
                    doorLeftLight: configuration.OneFilling.doorLeftLight,
                    doorRightLight: configuration.OneFilling.doorRightLight,
                };
                this.fillingsCategories = configuration.FillingsCategories;
                this.oneGlazingBead = configuration.OneGlazingBead;
                this.oneHandle = configuration.OneHandle;
                this.oneHandlesHeight = configuration.OneHandlesHeight;
                this.oneReinforcement = configuration.OneReinforcement;
                if (configuration.SealColor && configuration.SealColor.id) {
                    this.dictionary.sealColors[Number(configuration.SealColor.id)] = {
                        id: Number(configuration.SealColor.id),
                        name: {
                            [this.langCode]: configuration.SealColor.name,
                        },
                    };
                    this.sealColor = {
                        id: Number(configuration.SealColor.id),
                        isDefault: configuration.SealColor.isDefault,
                    };
                }
                if (configuration.SiliconeColor && configuration.SiliconeColor.id) {
                    this.dictionary.siliconeColors[Number(configuration.SiliconeColor.id)] = {
                        id: Number(configuration.SiliconeColor.id),
                        name: {
                            [this.langCode]: configuration.SiliconeColor.name,
                        },
                    };
                    this.siliconeColor = {
                        id: Number(configuration.SiliconeColor.id),
                        isDefault: configuration.SiliconeColor.isDefault,
                    };
                }
                this.hasHandle = configuration.HasHandle;
                this.handleType = configuration.HandleType as HandlesType;
                if (configuration.Hinge && configuration.Hinge.id) {
                    this.dictionary.hinges[Number(configuration.Hinge.id)] = {
                        id: Number(configuration.Hinge.id),
                        name: {
                            [this.langCode]: configuration.Hinge.name,
                        },
                        indexNo: configuration.Hinge?.index_no,
                        priceSource: configuration.Hinge.price_source,
                    };
                    if (configuration.HingeColor?.id) {
                        this.dictionary.accessoryColors[Number(configuration.HingeColor.id)] = {
                            id: Number(configuration.HingeColor.id),
                            name: {
                                [this.langCode]: configuration.HingeColor.name,
                            },
                            color: configuration.HingeColor.color,
                            code: configuration.HingeColor.code,
                            colorImg: configuration.HingeColor.colorImg,
                        };
                        this.hinge = {
                            id: Number(configuration.Hinge.id),
                            color: {
                                id: Number(configuration.HingeColor.id),
                                isDefault: configuration.HingeColor.isDefault,
                            },
                            visualizations: this.getHingeVisualization(configuration),
                        };
                    } else {
                        this.hinge = {
                            id: Number(configuration.Hinge.id),
                            visualizations: this.getHingeVisualization(configuration),
                        };
                    }
                }
                if (configuration.Lock && configuration.Lock.id) {
                    this.dictionary.locks[Number(configuration.Lock.id)] = {
                        id: Number(configuration.Lock.id),
                        name: {
                            [this.langCode]: configuration.Lock.name,
                        },
                        indexNo: configuration.Lock.index_no
                    };
                    this.lock = Number(configuration.Lock.id);
                }
                if (configuration.HasWarmEdge && configuration.WarmEdge) {
                    this.dictionary.glazingSpacers[Number(configuration.WarmEdge.id)] = {
                        id: Number(configuration.WarmEdge.id),
                        name: {
                            [this.langCode]: configuration.WarmEdge.name,
                        },
                    };
                    this.glazingSpacer = Number(configuration.WarmEdge.id);
                }
                if (
                    this.sashes.some(
                        sash =>
                            (sash.muntins && !!sash.muntins.length)
                            || sash.intSashes.some(
                                intSash => intSash.muntins && !!intSash.muntins.length
                            )
                    )
                ) {
                    this.muntins = {
                        sizeId: Number(configuration.MuntinsData.sizeId),
                        size: Number(configuration.MuntinsData.rWidth),
                        colorDraw: configuration.MuntinsData.colorDraw,
                        colorInner: null,
                        colorOuter: null,
                        type: {
                            id: Number(configuration.MuntinsData.typeCode),
                            type: configuration.MuntinsData.type,
                        },
                        duplex: configuration.MuntinsData.duplex,
                    };
                    if (configuration.MuntinsData.color) {
                        if (!this.dictionary.colors[Number(configuration.MuntinsData.color.id)]) {
                            this.dictionary.colors[
                                Number(configuration.MuntinsData.color.id)
                            ] = {
                                ...configuration.MuntinsData.color,
                                name: {
                                    [this.langCode]: configuration.MuntinsData.color.name,
                                },
                            };
                        }
                        this.muntins.colorInner = {
                            id: Number(configuration.MuntinsData.color.id),
                            isDefault: configuration.MuntinsData.color.isDefault,
                        };
                    }

                    if (configuration.MuntinsData.colorOut) {
                        if (!this.dictionary.colors[Number(configuration.MuntinsData.colorOut.id)]) {
                            this.dictionary.colors[
                                Number(configuration.MuntinsData.colorOut.id)
                            ] = {
                                ...configuration.MuntinsData.colorOut,
                                name: {
                                    [this.langCode]: configuration.MuntinsData.colorOut.name,
                                },
                            };
                        }
                        this.muntins.colorOuter = {
                            id: Number(configuration.MuntinsData.colorOut.id),
                            isDefault: configuration.MuntinsData.colorOut.isDefault,
                        };
                    }
                }
                this.dictionary.fittings[Number(configuration.Fitting.id)] = {
                    id: Number(configuration.Fitting.id),
                    name: {
                        [this.langCode]: configuration.Fitting.name,
                    },
                };
                this.fitting = Number(configuration.Fitting.id);

                this.accessories = configuration.Accessories.map<Accessory>(
                    accessory => new Accessory(accessory, undefined, this.dictionary, this.langCode)
                );
                this.BlockedAccessories = [];
                this.sideAccessories = {
                    top: configuration.SideAccessories.top.map<Accessory>(
                        accessory => new Accessory(accessory, undefined, this.dictionary, this.langCode)
                    ),
                    bottom: configuration.SideAccessories.bottom.map<Accessory>(
                        accessory => new Accessory(accessory, undefined, this.dictionary, this.langCode)
                    ),
                    left: configuration.SideAccessories.left.map<Accessory>(
                        accessory => new Accessory(accessory, undefined, this.dictionary, this.langCode)
                    ),
                    right: configuration.SideAccessories.right.map<Accessory>(
                        accessory => new Accessory(accessory, undefined, this.dictionary, this.langCode)
                    ),
                };

                this.doorPortal = configuration.doorPortal;
                this.hasSeparateRoller = configuration.hasSeparateRoller ?? false;
                this.rollerShutter = configuration.hasRoller || configuration.hasSeparateRoller
                    ? new RollerShutter(configuration.RollerShutter)
                    : null;
                this.mosquito = configuration.mosquito
                    ? new MosquitoConfiguration(configuration.mosquito)
                    : null;
                this.isMistakeProduct = configuration.IsMistakeProduct;
                this.hasWarranty = configuration.Warranty;
                this.frames = configuration.Frames.map(frame => ({
                    ...frame,
                    frame: frame.frame.map(f => {
                        if (f.reinforcement) {
                            this.dictionary.reinforcements[Number(f.reinforcement.id)] = {
                                id: Number(f.reinforcement.id),
                                name: {
                                    [this.langCode]: f.reinforcement.name,
                                },
                            };
                        }
                        return {
                            ...f,
                            reinforcement: f.reinforcement && f.reinforcement.id,
                        };
                    }),
                }));
                this.couplings = configuration.couplings.map(c => {
                    if (c.reinforcement) {
                        this.dictionary.reinforcements[Number(c.reinforcement.id)] = {
                            id: Number(c.reinforcement.id),
                            name: {
                                [this.langCode]: c.reinforcement.name,
                            },
                        };
                    }
                    if (c.color?.outer && !this.dictionary.colors[c.color.outer.id]) {
                        this.dictionary.constrColors[c.color.outer.id] = {
                            ...c.color.outer,
                            name: {
                                [this.langCode]: c.color.outer.name,
                            },
                        };
                    }
                    if (c.color?.inner && !this.dictionary.colors[c.color.inner.id]) {
                        this.dictionary.constrColors[c.color.inner.id] = {
                            ...c.color.inner,
                            name: {
                                [this.langCode]: c.color.inner.name,
                            },
                        };
                    }
                    if (c.color?.core && !this.dictionary.colors[c.color.core.id]) {
                        this.dictionary.colors[c.color.core.id] = {
                            ...c.color.core,
                            name: {
                                [this.langCode]: c.color.core.name,
                            },
                        };
                    }
                    if (c.color?.alushell && !this.dictionary.colors[c.color.alushell.id]) {
                        this.dictionary.colors[c.color.alushell.id] = {
                            ...c.color.alushell,
                            name: {
                                [this.langCode]: c.color.alushell.name,
                            },
                        };
                    }
                    return {
                        ...c,
                        dimensions: {
                            height: 0,
                            width: 0,
                        },
                        reinforcement: c.reinforcement && c.reinforcement.id,
                        color: {
                            inner: c.color ? iccColorToColorId(c.color.inner) : null,
                            outer: c.color ? iccColorToColorId(c.color.outer) : null,
                            core: c.color ? iccColorToColorId(c.color.core) : null,
                            alushell: c.color ? iccColorToColorId(c.color.alushell) : null,
                        }
                    };
                });
                this.parameters = {
                    weight: configuration.Weight,
                };
                this.weldFinishType = configuration.weldFinishType;
                this.drainagePosition = configuration.drainagePosition;
                this.hasGlazingWarranty = configuration.GlazingWarranty;
                this.measurements = configuration.Measurements;
                this.glazingBeadType = configuration.GlazingBeadType;
                this.doorSizes = configuration.doorSizes;
                Object.keys(configuration.doorSizesDictionary.door).forEach(id => {
                    const size = configuration.doorSizesDictionary.door[id];
                    this.dictionary.doorSizes[id] = {
                        id: size.id,
                        name: {
                            [this.langCode]: size.name,
                        },
                        passive: size.passive
                    };
                });
                Object.keys(configuration.doorSizesDictionary.doorLights).forEach(id => {
                    const size = configuration.doorSizesDictionary.doorLights[id];
                    this.dictionary.doorLightSizes[id] = {
                        id: size.id,
                        name: {
                            [this.langCode]: size.name,
                        },
                        type: size.type
                    };
                });

                if (configuration.thresholdColor && configuration.thresholdColor.id) {
                    this.dictionary.colors[
                        Number(configuration.thresholdColor.id)
                    ] = {
                        ...configuration.thresholdColor,
                        name: {
                            [this.langCode]: configuration.thresholdColor.name,
                        },
                    };
                    this.thresholdColor = {
                        id: Number(configuration.thresholdColor.id),
                        isDefault: configuration.thresholdColor.isDefault,
                    };
                }
                if (configuration.lippingColor && configuration.lippingColor.id) {
                    if (!this.dictionary.colors[Number(configuration.lippingColor.id)]) {
                        this.dictionary.colors[
                            Number(configuration.lippingColor.id)
                        ] = {
                            ...configuration.lippingColor,
                            name: {
                                [this.langCode]: configuration.lippingColor.name,
                            },
                        };
                    }
                    this.lippingColor = {
                        id: Number(configuration.lippingColor.id),
                        isDefault: configuration.lippingColor.isDefault,
                    };
                }

                if (configuration.innerLippingColor && configuration.innerLippingColor.id) {
                    if (!this.dictionary.colors[Number(configuration.innerLippingColor.id)]) {
                        this.dictionary.colors[
                            Number(configuration.innerLippingColor.id)
                        ] = {
                            ...configuration.innerLippingColor,
                            name: {
                                [this.langCode]: configuration.innerLippingColor.name,
                            },
                        };
                    }
                    this.innerLippingColor = {
                        id: Number(configuration.innerLippingColor.id),
                        isDefault: configuration.innerLippingColor.isDefault,
                    };
                }
                this.offerInfo = configuration?.OfferInfo || '';
            }
        }
        delete this.configuratorsDataService;
        delete this.colorsDefaultsService;
        delete this.config;
    }

    public static is(configuration): configuration is WindowConfiguration {
        return (
            configuration
            && (configuration instanceof WindowConfiguration
                || (configuration.$version
                    && !configuration.active
                    && !(configuration instanceof WindowSmallConfiguration)
                    && ['window', 'door', 'hs', 'folding_door', 'sliding_door'].indexOf(
                        configuration.type
                    ) > -1))
        );
    }

    protected runConversion(configuration, dataRequiredToUpdate: any) {
        let updatedConfiguration = core.copy(configuration);

        for (let version = configuration.$version + 1; version <= this.$version; version++) {
            updatedConfiguration.$version = version;
            if (WindowConfiguration.converters[version] && typeof WindowConfiguration.converters[version] === 'function') {
                updatedConfiguration = WindowConfiguration.converters[version](
                    updatedConfiguration,
                    dataRequiredToUpdate
                );
            }
        }

        (Object as any)
            .entries(updatedConfiguration)
            .forEach(([key, value]) => {
                this[key] = value;
            });
    }

    // eslint-disable-next-line max-statements
    private mapSash(
        sash: ActiveSash,
        parentSash: ActiveSash,
        configuration: WindowActiveConfiguration
    ): Sash {
        this.dictionary.fillings[sash.glazing.custom ? sash.glazing.id : sash.glazing.id] = {
            id: sash.glazing.custom ? sash.glazing.id : sash.glazing.id,
            extId: sash.glazing.extId,
            custom: sash.glazing.custom || false,
            name: {
                [this.langCode]: sash.glazing.name,
            },
            producer: sash.glazing.producer,
            code: sash.glazing.code,
            type: sash.glazing.type,
            weight: Number(sash.glazing.weight),
            availableSecondColor: sash.glazing.available_second_color,
            overlay_type: sash.glazing.panel_type,
            default_colors: sash.glazing.default_colors,
            thickness_mm: String(sash.glazing.thickness_mm),
            overlay_door_viewer_position_x_mm: Number(
                sash.glazing.overlay_door_viewer_position_x_mm
            ),
            overlay_door_viewer_position_y_mm: Number(
                sash.glazing.overlay_door_viewer_position_y_mm
            ),
            texture: sash.glazing.texture,
            overlay_height_texture_mm: sash.glazing.overlay_height_texture_mm,
            overlay_width_texture_mm: sash.glazing.overlay_width_texture_mm,
            inside_texture: sash.glazing.inside_texture,
            overlay_height_inside_texture_mm: sash.glazing.overlay_height_inside_texture_mm,
            overlay_width_inside_texture_mm: sash.glazing.overlay_width_inside_texture_mm,
            offset_x: sash.glazing.offset_x,
            offset_y: sash.glazing.offset_y,
            fillingColor: sash.glazing.color,
            indexNo: sash.glazing.index_no,
            security_level_inner: sash.glazing.security_level_inner,
            security_level_outer: sash.glazing.security_level_outer,
            glass_types: sash.glazing.glass_types,
            u: sash.glazing.u
        };

        (['selectedColor', 'selectedColorSecond'] as const).forEach(colorType => {
            if (sash.glazing[colorType]) {
                (['outer', 'inner'] as const).forEach(side => {
                    if (sash.glazing[colorType][side]?.id) {
                        const colorId =
                            Number(sash.glazing[colorType][side].id);
                        if (!this.dictionary.colors[colorId]) {
                            this.dictionary.colors[colorId] = {
                                ...sash.glazing[colorType][side],
                                name: {
                                    [this.langCode]: sash.glazing[colorType][side].name,
                                },
                            };
                        }
                    }
                });
            }
        });

        const configurationAccessoryToSash = sash && sash.type ? configuration.Accessories.filter(accessory => {
            const colorId = accessory.accessory.price_source === 'color' && accessory.accessory.color
                ? accessory.accessory.color.id
                : '-1';
            return ['DRA', 'DRP', 'DOA', 'DOP'].indexOf(sash.type.type) > -1
                && accessory.accessory.visualizations[colorId]
                && accessory.accessory.visualizations[colorId].type === 'space';
            }) : [];

        const newSash: Sash = {
            id: Number(sash.id),
            index: Number(sash.index),
            frameId: Number(sash.frameId),
            x: Number(sash.rx),
            y: Number(sash.ry),
            width: Number(sash.rWidth),
            height: Number(sash.rHeight),
            muntins: sash.muntins,
            nearMullions: {
                left: Number(sash.nearMullions.left),
                right: Number(sash.nearMullions.right),
                top: Number(sash.nearMullions.top),
                bottom: Number(sash.nearMullions.bottom),
            },
            nearAlignments: {
                left: Number(sash.nearAlignments.left),
                right: Number(sash.nearAlignments.right),
                top: Number(sash.nearAlignments.top),
                bottom: Number(sash.nearAlignments.bottom),
            },
            filling: {
                id: sash.glazing.id,
                type: sash.glazing.type,
                color: !sash.glazing.selectedColor
                    ? null
                    : {
                          inner: iccColorToColorId(sash.glazing.selectedColor.inner),
                          outer: iccColorToColorId(sash.glazing.selectedColor.outer),
                      },
                visualization:
                    (sash.glazing.visualizations && sash.glazing.visualizations[-1]) || null,
                default_colors: sash.glazing.default_colors,
                overlay_type: sash.glazing.panel_type,
                thickness_mm: sash.glazing.thickness_mm,
                overlayColor: !sash.glazing.selectedColorSecond
                    ? null
                    : {
                          inner: iccColorToColorId(sash.glazing.selectedColorSecond.inner),
                          outer: iccColorToColorId(sash.glazing.selectedColorSecond.outer),
                      },
            },
            glazingBead: sash.glazingBead as GlazingBeadProfile,
            bondedGlazing: Boolean(sash.bondedGlazing),
            panelGlazing: null,
            panelTrim: null,
            innerPanelTrim: null,
            panelInner: null,
            fillingSizes: sash.glazingSizes
                ? {
                      area: Number(sash.glazingSizes.area),
                      width: Number(sash.glazingSizes.width),
                      height: Number(sash.glazingSizes.height),
                      x: Number(sash.glazingSizes.x),
                      y: Number(sash.glazingSizes.y),
                  }
                : null,
            shape: sash.shape.shape,
            hardware: sash.hardware.map(accessory => new Accessory(accessory, undefined, this.dictionary, this.langCode)).concat(configurationAccessoryToSash.map(accessory => new Accessory({...accessory, configurationAccessoryToSash: true}, undefined, this.dictionary, this.langCode))),
            mosquito: sash.mosquito ? new MosquitoConfiguration(sash.mosquito) : null,
            weight: sash.weight,
        };

        ['u', 'rw', 'c', 'ctr', 'lt', 'g', 'psi'].forEach(field => {
            if (sash.glazing[field] != null) {
                newSash.filling[field] = Number(sash.glazing[field]);
            }
        });

        let doorSizeId: number | null = null;
        if (configuration.doorSizes) {
            if (parentSash.type) {
                if (parentSash.type.type === 'DRA' || parentSash.type.type === 'DOA') {
                    doorSizeId = configuration.doorSizes.sashSizeId;
                } else if (parentSash.type.type === 'DRP' || parentSash.type.type === 'DOP') {
                    doorSizeId = configuration.doorSizes.passiveSashSizeId;
                }
            }
            if (
                doorSizeId
                && sash.glazing.visualizations
                && sash.glazing.visualizations[doorSizeId]
            ) {
                newSash.filling.visualization = sash.glazing.visualizations[doorSizeId];
            }
        }

        if (
            configuration?.Layout?.sashes.length > 1 &&
            configuration?.Sashes[0].panelGlazing &&
            configuration?.Layout?.sashes.every(s => s?.type === configuration.Layout?.sashes[0]?.type)
        ) {
            sash.panelGlazing = configuration?.Sashes[0].panelGlazing;
        }

        if (sash.glazing.type === 'deco_panels' || sash.glazing.type === 'door_panels') {
            if (sash.panelGlazing && sash.panelGlazing.id) {
                this.dictionary.fillings[(sash.panelGlazing.id)] = {
                    id: (sash.panelGlazing.id),
                    extId: 0,
                    custom: sash.panelGlazing.custom || false,
                    name: {
                        [this.langCode]: sash.panelGlazing.name,
                    },
                    producer: sash.panelGlazing.producer,
                    type: sash.panelGlazing.type,
                    weight: Number(sash.panelGlazing.weight),
                    availableSecondColor: sash.panelGlazing.available_second_color,
                    texture: sash.panelGlazing.texture,
                    inside_texture: sash.panelGlazing.inside_texture,
                    fillingColor: sash.panelGlazing.color,
                    indexNo: sash.panelGlazing.index_no,
                };
                newSash.panelGlazing = {
                    id: (sash.panelGlazing.id),
                    color: null,
                    overlayColor: null,
                    custom: false,
                    visualization: null,
                };
                ['u', 'rw', 'c', 'ctr', 'lt', 'g', 'psi'].forEach(field => {
                    if (sash.panelGlazing[field] != null) {
                        newSash.panelGlazing[field] = (sash.panelGlazing[field]);
                    }
                });
                if (sash.panelGlazing.visualizations) {
                    const visualizationsForFilling =
                        sash.panelGlazing.visualizations[parseInt(sash.glazing.id)]
                        || sash.panelGlazing.visualizations[-1];
                    if (visualizationsForFilling) {
                        const visualization =
                            (doorSizeId && visualizationsForFilling[doorSizeId])
                            || visualizationsForFilling[-1];
                        if (visualization) {
                            newSash.panelGlazing.visualization = visualization;
                        }
                    }
                }
            }
            if (sash.panelTrim && sash.panelTrim.id) {
                this.dictionary.doorPanelTrims[sash.panelTrim.id] = {
                    ...sash.panelTrim,
                    name: {
                        [this.langCode]: sash.panelTrim.name,
                    },
                };
                newSash.panelTrim = {
                    id: sash.panelTrim.id,
                    isDefault: sash.panelTrim.isDefault,
                    visualization: null,
                };
                if (sash.panelTrim.visualizations) {
                    const visualizations =
                        (doorSizeId && sash.panelTrim.visualizations[doorSizeId])
                        || sash.panelTrim.visualizations[-1];
                    if (visualizations) {
                        const visualization = sash.glazing && visualizations.find(v => v.fillingIds?.map(Number).includes(parseInt(sash.glazing.id)));
                        if (visualization) {
                            newSash.panelTrim.visualization = visualization;
                        }
                    }
                }
            }
            if (sash.innerPanelTrim && sash.innerPanelTrim.id) {
                this.dictionary.doorPanelTrims[sash.innerPanelTrim.id] = {
                    ...sash.innerPanelTrim,
                    name: {
                        [this.langCode]: sash.innerPanelTrim.name,
                    },
                };
                newSash.innerPanelTrim = {
                    id: sash.innerPanelTrim.id,
                    isDefault: sash.innerPanelTrim.isDefault,
                    visualization: null,
                };
                if (sash.innerPanelTrim.visualizations) {
                    const visualizations =
                        (doorSizeId && sash.innerPanelTrim.visualizations[doorSizeId])
                        || sash.innerPanelTrim.visualizations[-1];
                    if (visualizations) {
                        const filling = sash.glazing?.type !== 'deco_panels' ? sash.panelInner : sash.glazing;
                        const visualization = filling && visualizations.find(v => v.fillingIds?.map(Number).includes(parseInt(filling.id)));
                        if (visualization) {
                            newSash.innerPanelTrim.visualization = visualization;
                        }
                    }
                }
            }
            newSash.panelType = sash.panelType;
            if (sash.panelInner && sash.panelInner.id) {
                this.dictionary.fillings[Number(sash.panelInner.id)] = {
                    id: Number(sash.panelInner.id),
                    extId: 0,
                    custom: false,
                    name: {
                        [this.langCode]: sash.panelInner.name,
                    },
                    producer: sash.panelInner.producer,
                    type: sash.panelInner.type,
                    weight: Number(sash.panelInner.weight),
                    availableSecondColor: sash.panelInner.available_second_inside_color,
                    texture: sash.panelInner.texture,
                    inside_texture: sash.panelInner.inside_texture,
                    fillingColor: sash.panelInner.color,
                    indexNo: sash.panelInner.index_no,
                };
                (['selectedColor', 'selectedColorSecond'] as const).forEach(colorType => {
                    if (sash.panelInner[colorType]) {
                        (['outer', 'inner'] as const).forEach(side => {
                            if (sash.panelInner[colorType][side]?.id) {
                                const colorId =
                                    Number(sash.panelInner[colorType][side].id);
                                if (!this.dictionary.colors[colorId]) {
                                    this.dictionary.colors[colorId] = {
                                        ...sash.panelInner[colorType][side],
                                        id: colorId,
                                        name: {
                                            [this.langCode]:
                                                sash.panelInner[colorType][side].name,
                                        },
                                    };
                                }
                            }
                        });
                    }
                });
                newSash.panelInner = {
                    id: Number(sash.panelInner.id),
                    type: sash.panelInner.type,
                    color: !sash.panelInner.selectedColor
                        ? null
                        : {
                              inner: iccColorToColorId(
                                  sash.panelInner.selectedColor.inner
                              ),
                              outer: iccColorToColorId(
                                  sash.panelInner.selectedColor.outer
                              ),
                          },
                    visualization:
                        (sash.panelInner.visualizations && sash.panelInner.visualizations[-1])
                        || null,
                    default_colors: sash.panelInner.default_colors,
                    overlay_type: sash.panelInner.panel_type,
                    thickness_mm: sash.panelInner.thickness_mm,
                    overlayColor: !sash.panelInner.selectedColorSecond
                        ? null
                        : {
                              inner: iccColorToColorId(
                                  sash.panelInner.selectedColorSecond.inner
                              ),
                              outer: iccColorToColorId(
                                  sash.panelInner.selectedColorSecond.outer
                              ),
                          },
                };
                if (
                    doorSizeId
                    && sash.panelInner.visualizations
                    && sash.panelInner.visualizations[doorSizeId]
                ) {
                    newSash.panelInner.visualization =
                        sash.panelInner.visualizations[doorSizeId];
                }
            }
        }

        if (sash.parentId != null) {
            newSash.parentId = sash.parentId;
        } else {
            this.dictionary.sashTypes[Number(sash.type.id)] = {
                id: Number(sash.type.id),
                type: sash.type.type,
                name: {
                    [this.langCode]: sash.type.type,
                },
                handlePosition: sash.type.handle_position,
                passive: sash.type.passive,
            };
            newSash.type = {
                id: Number(sash.type.id),
                handlePosition: sash.type.handle_position,
                passive: sash.type.passive
            };
            newSash.intSashes = sash.intSashes.map(field =>
                this.mapSash(field, sash, configuration)
            );
            newSash.intAlignments = sash.intAlignments;
            newSash.intMullions = sash.intMullions.map(this.mapMullion.bind(this));
            newSash.intAdjacentSashes = {
                top: sash.intEdgeSashes.top
                    .filter(e => e !== null && e !== undefined)
                    .map(edgeSash => Number(edgeSash)),
                bottom: sash.intEdgeSashes.bottom
                    .filter(e => e !== null && e !== undefined)
                    .map(edgeSash => Number(edgeSash)),
                left: sash.intEdgeSashes.left
                    .filter(e => e !== null && e !== undefined)
                    .map(edgeSash => Number(edgeSash)),
                right: sash.intEdgeSashes.right
                    .filter(e => e !== null && e !== undefined)
                    .map(edgeSash => Number(edgeSash)),
            };

            newSash.oneGlazing = sash.oneGlazing;
            newSash.oneGlazingBead = sash.oneGlazingBead;

            newSash.frame = {
                top: {
                    ...sash.frame.top,
                    reinforcement: sash.frame.top.reinforcement && sash.frame.top.reinforcement.id,
                },
                bottom: {
                    ...sash.frame.bottom,
                    reinforcement:
                        sash.frame.bottom.reinforcement && sash.frame.bottom.reinforcement.id,
                },
                left: {
                    ...sash.frame.left,
                    reinforcement:
                        sash.frame.left.reinforcement && sash.frame.left.reinforcement.id,
                },
                right: {
                    ...sash.frame.right,
                    reinforcement:
                        sash.frame.right.reinforcement && sash.frame.right.reinforcement.id,
                },
            };
            if (sash.frame.top.reinforcement) {
                this.dictionary.reinforcements[Number(sash.frame.top.reinforcement.id)] = {
                    id: Number(sash.frame.top.reinforcement.id),
                    name: {
                        [this.langCode]: sash.frame.top.reinforcement.name,
                    },
                };
            }
            if (sash.frame.bottom.reinforcement) {
                this.dictionary.reinforcements[Number(sash.frame.bottom.reinforcement.id)] = {
                    id: Number(sash.frame.bottom.reinforcement.id),
                    name: {
                        [this.langCode]: sash.frame.bottom.reinforcement.name,
                    },
                };
            }
            if (sash.frame.left.reinforcement) {
                this.dictionary.reinforcements[Number(sash.frame.left.reinforcement.id)] = {
                    id: Number(sash.frame.left.reinforcement.id),
                    name: {
                        [this.langCode]: sash.frame.left.reinforcement.name,
                    },
                };
            }
            if (sash.frame.right.reinforcement) {
                this.dictionary.reinforcements[Number(sash.frame.right.reinforcement.id)] = {
                    id: Number(sash.frame.right.reinforcement.id),
                    name: {
                        [this.langCode]: sash.frame.right.reinforcement.name,
                    },
                };
            }

            if (
                ['F', 'FF', 'OFF', 'DS', 'ODS', 'DSC', 'DRP', 'DOP'].indexOf(
                    this.dictionary.sashTypes[newSash.type.id].type
                ) === -1
            ) {
                if (sash.handle) {
                    this.dictionary.handles[Number(sash.handle.id)] = {
                        id: Number(sash.handle.id),
                        handleType: sash.handle.handle_type,
                        name: {
                            [this.langCode]: sash.handle.name,
                        },
                    };
                    if (sash.handleColor?.id) {
                        this.dictionary.accessoryColors[Number(sash.handleColor.id)] = {
                            id: Number(sash.handleColor.id),
                            name: {
                                [this.langCode]: sash.handleColor.name,
                            },
                            color: sash.handleColor.color,
                            code: sash.handleColor.code,
                            colorImg: sash.handleColor.color_img,
                        };
                    }

                    newSash.handleInner = sash.handle
                        ? {
                            id: Number(sash.handle.id),
                            color: sash.handleColor
                                ? {
                                        id: Number(sash.handleColor.id),
                                        isDefault: sash.handleColor.isDefault,
                                    }
                                : null,
                            visualization:
                                sash.handle.visualizations
                                && ((sash.handleColor
                                    && sash.handle.visualizations[sash.handleColor.id])
                                    || sash.handle.visualizations[-1]),
                        }
                        : null;
                }
                if (sash.handleOuter) {
                    this.dictionary.handles[Number(sash.handleOuter.id)] = {
                        id: Number(sash.handleOuter.id),
                        handleType: sash.handleOuter.handle_type,
                        name: {
                            [this.langCode]: sash.handleOuter.name ,
                        },
                        handleOuterDescription: configuration?.HandleOuter?.description || ''
                    };
                    if (sash.handleOuterColor?.id) {
                        this.dictionary.accessoryColors[Number(sash.handleOuterColor.id)] = {
                            id: Number(sash.handleOuterColor.id),
                            name: {
                                [this.langCode]: sash.handleOuterColor.name,
                            },
                            color: sash.handleOuterColor.color,
                            code: sash.handleOuterColor.code,
                            colorImg: sash.handleOuterColor.color_img,
                        };
                    }
                    newSash.handleOuter = sash.handleOuter
                        ? {
                            id: Number(sash.handleOuter.id),
                            color: sash.handleOuterColor
                                ? {
                                        id: Number(sash.handleOuterColor.id),
                                        isDefault: sash.handleOuterColor.isDefault,
                                    }
                                : null,
                            visualization:
                                sash.handleOuter.visualizations
                                && ((sash.handleOuterColor
                                    && sash.handleOuter.visualizations[sash.handleOuterColor.id])
                                    || sash.handleOuter.visualizations[-1]),
                        }
                        : null;
                }
                newSash.handleHeight = Number(sash.rHandleY);
                newSash.defaultHandleHeight = sash.defaultHandleHeight;
                newSash.handleHeightType = sash.handleHeightType;
                newSash.lock = sash.lock;
            }
        }

        return newSash;
    }

    private mapMullion(mullion: ActiveMullion): MullionV2 {
        const newMullion: MullionV2 = {
            id: Number(mullion.id),
            isDefault: mullion.isDefault,
            frameId: mullion.frameId,
            profileId: Number(mullion.profileId),
            position: Number(mullion.direction === 'horizontal' ? mullion.ry : mullion.rx),
            length: Number(mullion.direction === 'horizontal' ? mullion.rWidth : mullion.rHeight),
            shift: Number(mullion.direction === 'horizontal' ? mullion.rx : mullion.ry),
            direction: mullion.direction,
            type: mullion.type,
            adjacentSashes: {},
            parallelAlignments: {
                top: [],
                bottom: [],
                left: [],
                right: [],
            },
            perpendicularAlignments: {
                top: [],
                bottom: [],
                left: [],
                right: [],
            },
            parallelMullions: {},
            perpendicularMullions: {},
            reinforcement: mullion.reinforcement && mullion.reinforcement.id,
        };
        if (mullion.reinforcement) {
            this.dictionary.reinforcements[Number(mullion.reinforcement.id)] = {
                id: Number(mullion.reinforcement.id),
                name: {
                    [this.langCode]: mullion.reinforcement.name,
                },
            };
        }
        if (newMullion.direction === 'horizontal') {
            newMullion.adjacentSashes = {
                top: mullion.multiAlignTop
                    .filter(e => Common.isObject(e))
                    .map(sash => Number(sash.id)),
                bottom: mullion.multiAlignBottom
                    .filter(e => Common.isObject(e))
                    .map(sash => Number(sash.id)),
            };
            newMullion.perpendicularMullions = {
                top: mullion.multiAlignTopDiv
                    .filter(e => Common.isObject(e))
                    .map(div => Number(div.id)),
                bottom: mullion.multiAlignBottomDiv
                    .filter(e => Common.isObject(e))
                    .map(div => Number(div.id)),
            };
            newMullion.parallelAlignments = {
                top: mullion.parallelAlignments.top || [],
                bottom: mullion.parallelAlignments.bottom || [],
            };
            newMullion.perpendicularAlignments = {
                top: mullion.perpendicularAlignments.top || [],
                bottom: mullion.perpendicularAlignments.bottom || [],
            };
        } else {
            newMullion.adjacentSashes = {
                left: mullion.multiAlignLeft
                    .filter(e => Common.isObject(e))
                    .map(sash => Number(sash.id)),
                right: mullion.multiAlignRight
                    .filter(e => Common.isObject(e))
                    .map(sash => Number(sash.id)),
            };
            newMullion.perpendicularMullions = {
                left: mullion.multiAlignLeftDiv
                    .filter(e => Common.isObject(e))
                    .map(div => Number(div.id)),
                right: mullion.multiAlignRightDiv
                    .filter(e => Common.isObject(e))
                    .map(div => Number(div.id)),
            };
            newMullion.parallelAlignments = {
                left: mullion.parallelAlignments.left || [],
                right: mullion.parallelAlignments.right || [],
            };
            newMullion.perpendicularAlignments = {
                left: mullion.perpendicularAlignments.left || [],
                right: mullion.perpendicularAlignments.right || [],
            };
        }
        return newMullion;
    }

    private getHingeVisualization(conf) {
        const sashHeight = this.getSashHeight(conf);

        const visualization = conf.HingeColor && conf.HingeColor.id && conf.Hinge.visualizations[conf.HingeColor.id]
            ? conf.Hinge.visualizations[conf.HingeColor.id]
            : conf.Hinge.visualizations[-1];
        if (visualization) {
            const hingeVisualization = Object.create(visualization);

            hingeVisualization.forEach((item) => {
                if (!(sashHeight >= Number(item.windowHeightFrom) && sashHeight <= Number(item.windowHeightTo))) {
                    item.positionYMM = undefined;
                } else if (item.hingePositionFrom === 'from_the_top') {
                    item.positionYMM = sashHeight - Number(item.hingeHeight);
                } else {
                    item.positionYMM = Number(item.hingeHeight);
                }
            });

            if (visualization) {
                conf.doorHardware.visualizations.decorativeHingeCovers = this.getDecorativeHingeCoverVisualization(conf, sashHeight);
                return visualization;
            }
        }
    }

    private getDecorativeHingeCoverVisualization(conf, sashHeight: number) {
        if (conf.doorHardware.visualizations?.decorativeHingeCovers) {
            const hingeVisualization = Object.create(conf.doorHardware.visualizations?.decorativeHingeCovers);

            hingeVisualization.forEach((item) => {
                if (!(sashHeight >= Number(item.windowHeightFrom) && sashHeight <= Number(item.windowHeightTo))) {
                    item.positionYMM = undefined;
                } else if (item.hingePositionFrom === 'from_the_top') {
                    item.positionYMM = sashHeight - Number(item.hingeHeight);
                } else {
                    item.positionYMM = Number(item.hingeHeight);
                }
            });

            return conf.doorHardware.visualizations.decorativeHingeCovers;
        }
    }

    private getSashHeight(conf) {
        const topLight = (conf.doorSizes && conf.doorSizes.topLightSize) || 0;
        const bottomLight = (conf.doorSizes && conf.doorSizes.bottomLight) || 0;

        const thresholdSpaceSash = conf.UsedProfiles.find(p => p.type === "threshold")?.spaceSash || 0;
        const frameSpaceSash = conf.UsedProfiles.find(p => p.type === "frame")?.spaceSash || 0;

        const sashHeight = (conf.Height - thresholdSpaceSash - frameSpaceSash - topLight - bottomLight);

        return sashHeight;
    }

}
export type IWindowConfiguration = Pick<WindowConfiguration, keyof WindowConfiguration>;
