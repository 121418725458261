// import * as hash from 'object-hash';
import { PriceSegment } from './PriceSegment';
import { PricePart } from './PricePart';
import { PriceFunctions, PriceFunction } from './PriceFunctions';
import { PriceElems } from './PriceElems';
import { DiscountsAndMultipliersService } from './discounts-and-multipliers.service';
import { CustomPricesService } from '@icc/common/custom-price/custom-prices.service';
import { Injectable, Inject, Injector } from '@angular/core';
import { StateService } from '@icc/common/state.service';
import { ConfigurationsService } from '@icc/common/configurations/configurations.service';
import { APP_CONFIG, AppConfig, AppConfigFactory } from '@icc/common/config';
import { UserService } from '@icc/common/user.service';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { core, logger } from '@icc/common/helpers';
import { Common } from '@icc/common/Common';
import { OfferDiscountsService } from '@icc/common/offers/OfferDiscountsService';
import { WindowActiveConfiguration } from '@icc/common';
import { currencyExchange } from '@icc/helpers';

/**
 * Fabryka bazowych funkcji do wyceny.
 *
 * @export
 * @class PriceBaseService
 */
@Injectable()
export class PriceBaseService {
    private counter = 0;
    /**
     * Creates an instance of PriceBaseService.
     *
     * @param {Injector} injector Injector
     * @param {any} Core Core
     * @param {any} configuratorsDataService ConfiguratorsDataService
     * @param {any} userService UserService
     * @param {any} IccConfig IccConfig
     *
     * @memberOf PriceBaseService
     */
    constructor(
        private injector: Injector,
        private stateService: StateService,
        private configuratorsDataService: ConfiguratorsDataService,
        private customPricesService: CustomPricesService,
        private discountsAndMultipliersService: DiscountsAndMultipliersService,
        private userService: UserService,
        private configurationsService: ConfigurationsService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {}

    /**
     * Dolicza do ceny marżę i współczynnik rynku.
     *
     * @param {number} price Cena
     * @return {number} Cena z marżą  i współczynnikiem rynku.
     */
    addMarginMarketFactor(price, noSystemMultiplier = false) {
        const margin =
            Common.isObject(this.stateService.state)
            && Common.isArray(this.stateService.state.offers)
            && this.stateService.state.offers.length
            && Common.isObject(this.stateService.state.offers[0].doc)
                ? this.stateService.state.offers[0].doc.dealer_margin * 1
                : 0;
        let marketFactor = 1;
        let marketMargin = 1;
        if (margin) {
            price *= 1 + margin / 100;
        }

        if (Common.isDefined(this.configuratorsDataService.data.marketFactor)) {
            marketFactor = parseFloat(this.configuratorsDataService.data.marketFactor);
        }

        if (
            Common.isDefined(this.configuratorsDataService.data.marketMargin)
            && !this.userService.get().access
        ) {
            marketMargin = 1 + parseFloat(this.configuratorsDataService.data.marketMargin) / 100;
        }
        let systemMultiplier = 1;
        if (this.config().IccConfig.Configurators.price.systemMultiplierToAllSegments && !noSystemMultiplier) {
            const dealerId = Common.isObject(this.stateService.state)
                && Common.isArray(this.stateService.state.offers)
                && this.stateService.state.offers.length
                && Common.isObject(this.stateService.state.offers[0].doc)
                    ? this.stateService.state.offers[0].doc.dealer_id
                    : null;
            const multipliersSource = this.discountsAndMultipliersService.multipliers[dealerId] || this.discountsAndMultipliersService.multipliers;
            const systemId = WindowActiveConfiguration.is(this.configurationsService.conf.Current) && this.configurationsService.conf.Current.System.id || null;
            systemMultiplier = systemId && multipliersSource.WindowLine && multipliersSource.WindowLine[systemId] || 1;
        }

        price *= marketFactor * marketMargin * systemMultiplier;

        if (this.config().IccConfig.Configurators.grossPriceInSummaryB2C) {
            const taxValue = this.getTaxValue();
            price *= (100 + Number(taxValue)) / 100;
        }
        return price;
    }

    /**
     * Ustawia cenę w konfiguracji.
     *
     * @param {any} config Konfiguracja
     * @returns {number} Cena
     *
     * @memberOf PriceBaseService
     */
    setPrice(config, offer = this.stateService.getCurrentOffer(), allDiscounts = null) {
        if (!offer) {
            logger.error('Brak oferty w trakcie przeliczania ceny');
        }
        const type = config.type;
        const dealerId = offer ? offer.dealer_id : null;
        const dealerMargin = offer ? offer.dealer_margin * 1 : 0;
        let configPriceElems = core.copy(PriceElems[type]) || {};
        if (Common.isString(configPriceElems)) {
            configPriceElems = core.copy(PriceElems[configPriceElems]) || {};
        }
        const multipliers = offer
            ? offer.multipliers
            : this.config().multipliers
            ? this.config().multipliers
            : allDiscounts
            ? allDiscounts.multipliers
            : null;

        this.counter = 0;
        const configNoPriceCauses = [];
        const configNoPriceElems = {
            accessories: [],
            sashAccessories: [],
            sideAccessories: [],
            complementaryGoods: {
                windowsill: [],
                cassonetto: [],
                glass: [],
                accessory: [],
                profile: [],
            },
        };

        let price = 0;
        let listPrice = 0;

        try {
            if (this.config().IccConfig.Offer.listPrice && (this.config()?.listPriceEnableInMarket || false )) {
                this.calculateListPrice(config, configPriceElems, configNoPriceCauses, configNoPriceElems, dealerId, dealerMargin, offer, multipliers, allDiscounts);
            }
            const listPriceEnableInMarket = this.config().IccConfig.Offer.listPrice ? !this.config().listPriceEnableInMarket : false;

            const priceStack = this.calculate(
                config,
                configPriceElems,
                configNoPriceCauses,
                configNoPriceElems,
                dealerId,
                multipliers,
                listPriceEnableInMarket
            );
            price = this.standardActions(
                config,
                priceStack,
                configPriceElems,
                configNoPriceElems,
                configNoPriceCauses,
                dealerId,
                dealerMargin,
                offer,
                multipliers,
                allDiscounts
            );
        } catch (err) {
            price = NaN;
            logger.error(err);
            config.Price = NaN;
        }

        config.PriceValid = true;
        return price;
    }

    calculateListPrice(config, configPriceElems, configNoPriceCauses, configNoPriceElems, dealerId, dealerMargin, offer, multipliers, allDiscounts) {
        const listPriceStack = this.calculate(
            config,
            configPriceElems,
            configNoPriceCauses,
            configNoPriceElems,
            dealerId,
            multipliers,
            true
        );
        const listPrice = this.standardActions(
            config,
            listPriceStack,
            configPriceElems,
            configNoPriceElems,
            configNoPriceCauses,
            dealerId,
            dealerMargin,
            offer,
            multipliers,
            allDiscounts
        );
        config.ListPriceSegments = core.copy(config.PriceSegments);
        config.ListPriceGross = config.PriceGross;
        config.ListPrice = config.Price;
        config.ListPriceGrossBeforePromotions = config.PriceGrossBeforePromotions;
        config.ListPriceBeforePromotions = config.PriceBeforePromotions;
        config.ListPriceGrossAfterDiscounts = config.PriceGrossAfterDiscounts;
        config.ListPriceAfterDiscountsInCurrency = config.PriceAfterDiscountsInCurrency;
        config.ListPriceSegmentsNoMargin = core.copy(config.PriceSegmentsNoMargin);
        config.ListPriceSegmentsBeforePromotions = core.copy(config.PriceSegmentsBeforePromotions);
        config.ListPriceParts = core.copy(config.PriceParts);
        config.ListPricePartsNoMargin = core.copy(config.PricePartsNoMargin);
        config.ListPricePartsBeforePromotions = core.copy(config.PricePartsBeforePromotions);
        config.ListDiscount = config.Discount;
    }

    setPriceForProduct(config, allDiscounts) {
        const type = config.type;
        let configPriceElems = core.copy(PriceElems[type]) || {};
        if (Common.isString(configPriceElems)) {
            configPriceElems = core.copy(PriceElems[configPriceElems]) || {};
        }
        this.counter = 0;
        const configNoPriceCauses = [];
        const configNoPriceElems = {
            accessories: [],
            sashAccessories: [],
            sideAccessories: [],
            complementaryGoods: {
                windowsill: [],
                cassonetto: [],
                glass: [],
                accessory: [],
                profile: [],
            },
        };

        let price = 0;
        let priceStack: PriceSegment[];
        let error;
        try {
            priceStack = this.calculate(
                config,
                configPriceElems,
                configNoPriceCauses,
                configNoPriceElems,
                null,
                allDiscounts.multipliers
            );
            price = this.standardActions(
                config,
                priceStack,
                configPriceElems,
                configNoPriceElems,
                configNoPriceCauses,
                null,
                null,
                allDiscounts.multipliers,
                null
            );
        } catch (err) {
            error = err.message;
            price = NaN;
            config.Price = NaN;
        }
        const emptyPriceStack = priceStack?.filter(p => p && p.value === null) || [error];
        config.PriceValid = true;
        return {price, emptyPriceStack};
    }

    /**
     * Standardowe akcje po kazdym wyliczaniu cen: marza, wspolczynniki, przypisanie do konfiguracji
     * @param  {object} conf          Konfiguracja
     * @param  {number} price         Wyliczona cena
     * @param  {object} PriceElems    Elementy skladowe ceny
     * @param  {object} NoPriceElems  Elementy ktorych cen nie da sie policzyc
     * @param  {array}  NoPriceCauses Powody niewyliczenia ceny
     */
    standardActions(
        conf,
        priceStack: PriceSegment[],
        PriceElems,
        NoPriceElems,
        NoPriceCauses,
        dealerId,
        margin,
        offer,
        multipliers,
        allDiscounts = null
    ) {
        let marketFactor = 1;
        let marketMargin = 1;

        this.discountsAndMultipliersService.suppMultipliers(
            priceStack,
            conf.type,
            this.extendId.bind(this),
            multipliers,
            dealerId
        );

        if (Common.isDefined(this.configuratorsDataService.data.marketFactor)) {
            marketFactor = parseFloat(this.configuratorsDataService.data.marketFactor);
        }
        priceStack.push(
            ...this.extendId([
                {
                    type: 'market',
                    baseValue: marketFactor,
                    value: marketFactor,
                    valueType: 'percent',
                    data: {
                        marketId: this.configuratorsDataService.data.marketId,
                    },
                },
            ])
        );
        PriceElems.factor = marketFactor;
        core.countFactor(PriceElems, marketFactor);
        const priceStackNoMargin = core.copy(priceStack);

        const pricePartsNoMargin = [];
        const priceNoMargin = this.sum(priceStackNoMargin, pricePartsNoMargin);
        this.countPriceElems(priceStackNoMargin, PriceElems, conf.System);

        conf.PricePartsNoMargin = pricePartsNoMargin;
        conf.PriceElemsNoMargin = core.copy(PriceElems);
        conf.PriceNoMargin = priceNoMargin;
        conf.PriceSegmentsNoMargin = priceStackNoMargin;

        if (margin) {
            priceStack.push(
                ...this.extendId([
                    {
                        type: 'margin',
                        baseValue: 1 + margin / 100,
                        value: 1 + margin / 100,
                        valueType: 'multiplier',
                        data: {},
                    },
                ])
            );
            core.countFactor(PriceElems, 1 + margin / 100);
        }

        if (
            Common.isDefined(this.configuratorsDataService.data.marketMargin)
            && !this.userService.get().access
        ) {
            marketMargin = 1 + parseFloat(this.configuratorsDataService.data.marketMargin) / 100;
        }
        priceStack.push(
            ...this.extendId([
                {
                    type: 'marketMargin',
                    baseValue: marketMargin,
                    value: marketMargin,
                    valueType: 'multiplier',
                    data: {
                        marketId: this.configuratorsDataService.data.marketId
                    },
                },
            ])
        );
        PriceElems.margin = marketMargin;
        PriceElems.margin = margin;
        core.countFactor(PriceElems, marketMargin);

        const taxValue = this.getTaxValue();
        const pricePartsBeforePromotions = [];
        const priceStackBeforePromotions = core.copy(priceStack);
        const priceBeforePromotions = this.sum(priceStackBeforePromotions, pricePartsBeforePromotions);
        conf.PriceBeforePromotions = priceBeforePromotions;
        if (
            this.config().preset === 'b2c'
            && this.config().IccConfig.Configurators.grossPriceInSummaryB2C
        ) {
            conf.PriceGrossBeforePromotions = (priceBeforePromotions * (100 + Number(taxValue))) / 100;
        }
        conf.PriceElems = PriceElems;
        conf.NoPriceElems = NoPriceElems;
        conf.NoPriceCauses = NoPriceCauses;
        conf.PricePartsBeforePromotions = pricePartsBeforePromotions;
        conf.PriceSegmentsBeforePromotions = priceStackBeforePromotions;

        conf.DiscountGroupsNoMargin = this.discountsAndMultipliersService.getDiscountGroups(
            conf.PriceSegmentsNoMargin
        );
        conf.PriceSegments = priceStack;
        conf.DiscountGroups = this.discountsAndMultipliersService.getDiscountGroups(
            conf.PriceSegments
        );

        this.discountsAndMultipliersService.suppPromotions(
            priceStack,
            this.extendId.bind(this),
            this.configuratorsDataService.data.promotions || []
        );
        const priceParts = [];
        const price = this.sum(priceStack, priceParts);
        conf.Price = price;
        if (
            this.config().preset === 'b2c'
            && this.config().IccConfig.Configurators.grossPriceInSummaryB2C
        ) {
            conf.PriceGross = (price * (100 + Number(taxValue))) / 100;
        }
        conf.PriceParts = priceParts;
        conf.PriceSegments = priceStack;

        const priceInCurrency = currencyExchange(price, offer?.currency ?? this.config().currency, true);

        if (this.userService.get().access === 'Super Admin' || this.userService.get().access === 'Administratorzy') {
            conf.PriceAfterDiscountsInCurrency = priceInCurrency;
            conf.Discount = 0;
        } else {
            const buyDiscounts = allDiscounts && allDiscounts.buyDiscounts || this.discountsAndMultipliersService.getBuyDiscounts();
            const saleDiscounts = allDiscounts && allDiscounts.saleDiscounts || this.discountsAndMultipliersService.getSaleDiscounts();
            const offerDiscounts = allDiscounts && allDiscounts.offerDiscounts || this.discountsAndMultipliersService.getOfferDiscountSync();

            const discounts = OfferDiscountsService.generateGroupDiscounts(
                {
                    offer,
                    details: this.configurationsService.createSimpleConfiguration(conf),
                    group_discounts: [],
                    configuration: conf,
                    confType: conf.type,
                    price: priceInCurrency,
                    dealer_price: currencyExchange(priceNoMargin, offer?.currency ?? this.config().currency),
                },
                this.config().IccConfig,
                null,
                buyDiscounts,
                saleDiscounts,
                margin,
                true,
            );

            conf.PriceAfterDiscountsInCurrency = priceInCurrency - discounts.client_discount;
            const groupDiscounts = OfferDiscountsService.groupDiscounts(
                offerDiscounts,
                conf.PriceAfterDiscountsInCurrency,
                conf.PriceAfterDiscountsInCurrency,
                this.userService.get(),
                offer,
                this.config().IccConfig,
                conf,
                false,
                offer ? conf.PriceAfterDiscountsInCurrency + offer.dealer_price_before_discount - (this.configurationsService.conf && this.configurationsService.conf.Edit ? this.configurationsService.conf?.editedPositionPrices?.dealerPrice : 0) : conf.PriceAfterDiscountsInCurrency,
                offer ? conf.PriceAfterDiscountsInCurrency + offer.client_price_before_discount - (this.configurationsService.conf && this.configurationsService.conf.Edit ? this.configurationsService.conf?.editedPositionPrices?.clientPrice : 0) : conf.PriceAfterDiscountsInCurrency,
            );
            conf.PriceAfterDiscountsInCurrency = groupDiscounts.clientPrice;
            conf.Discount = core.round(
                ((priceInCurrency - discounts.client_discount - groupDiscounts.clientPrice)
                    / (priceInCurrency - discounts.client_discount))
                * 100
            );
        }
        if (
            this.config().preset === 'b2c'
            && this.config().IccConfig.Configurators.grossPriceInSummaryB2C
        ) {
            conf.PriceGrossAfterDiscounts =
                (conf.PriceAfterDiscountsInCurrency * (100 + Number(taxValue))) / 100;
        }

        return price;
    }

    getTaxValue() {
        const user = this.userService.get();
        const taxValue =
            this.config().preset === 'b2c'
            && (Common.isArray(this.configuratorsDataService.data.taxRates)
            && Common.isDefined(this.configuratorsDataService.data.taxRates[0])
                ? this.configuratorsDataService.data.taxRates[0].value
                : 0);
        return taxValue;
    }

    /**
     * Wyznacza schemat liczenia ceny.
     *
     * @param {any} config              Konfiguracja
     * @param {any} configPriceElems    Elementy wyceny
     * @param {any} configNoPriceCauses Powody braku wyceny
     * @param {any} configNoPriceElems  Elementy bez ceny
     * @returns {PriceSegment[]} Schemat liczenia ceny
     *
     * @memberOf PriceBaseService
     */
    // eslint-disable-next-line max-statements
    calculate(
        config,
        configPriceElems,
        configNoPriceCauses,
        configNoPriceElems,
        dealerId,
        multipliers,
        listPrice = false
    ): PriceSegment[] {
        const type = config.type;
        let functions = PriceFunctions.priceFn[type] || [];
        if (Common.isString(functions)) {
            functions = PriceFunctions.priceFn[functions] || [];
        }
        let mistakeProductFunctions = PriceFunctions.mistakeProductFn[type] || [];
        if (Common.isString(functions)) {
            mistakeProductFunctions =
                PriceFunctions.mistakeProductFn[mistakeProductFunctions] || [];
        }
        const priceStack: PriceSegment[] = [];
        if (config.IsMistakeProduct) {
            priceStack.push({
                type: 'mistakeProduct',
                baseValue: core.num(config.Price),
                value: core.num(config.Price),
                valueType: 'value',
                data: {},
            });
        }
        try {
            for (const funcName of functions) {
                if (config.IsMistakeProduct && mistakeProductFunctions.indexOf(funcName) === -1) {
                    continue;
                }
                const func = PriceFunctions.map.get(funcName);
                const funcData = {};
                const funcThis: any = this.injector.get(func.class);
                if (func && func.requiredData) {
                    for (const i in func.requiredData) {
                        const data = core.deepFind(
                            {
                                conf: config,
                                data: this.configuratorsDataService.data,
                                price: this.customPricesService.get(dealerId),
                                multipliers:
                                    multipliers
                                    || this.discountsAndMultipliersService.getMultipliers(),
                                user: this.userService.get(),
                                dealerId,
                            },
                            func.requiredData[i]
                        );
                        funcData[i] = data;
                    }
                }
                funcThis.customPrices = this.customPricesService.get(dealerId);
                const funcBinded: PriceFunction = func.bind(funcThis);
                priceStack.push(
                    ...this.extendId(
                        funcBinded(
                            {
                                PriceStack: priceStack,
                                PriceElems: configPriceElems,
                                NoPriceCauses: configNoPriceCauses,
                                NoPriceElems: configNoPriceElems,
                                listPrice,
                            },
                            funcData
                        )
                    )
                );
            }
        } catch (error) {
            throw error;
        }
        return priceStack;
    }

    /**
     * Wylicza cenę ze schematu wyceny
     *
     * @param {PriceSegment[]} priceStack Schemat wyceny
     * @returns {number} Wyliczona cena
     *
     * @memberOf PriceBaseService
     */
    sum(priceStack: PriceSegment[], priceParts: PricePart[]) {
        if (Common.isArray(this.config().IccConfig.Configurators.price.order)) {
            const order = this.config().IccConfig.Configurators.price.order;
            priceStack = priceStack
                .filter(s => s)
                .sort((a, b) => {
                    if (order.indexOf(a.type) > -1 && order.indexOf(b.type) > -1) {
                        return order.indexOf(a.type) - order.indexOf(b.type);
                    } else if (order.indexOf(a.type) === -1) {
                        return 1;
                    } else {
                        return -1;
                    }
                });
        }
        priceStack.map<PriceSegment>(this.extendTo(priceStack).bind(this));
        priceStack
            .filter(s => s && s.valueType === 'multiplier')
            .forEach(s => {
                priceStack
                    .filter(t => t && t.valueType === 'percent')
                    .filter(this.filterTo(s.to).bind(this))
                    .forEach(t => {
                        if (
                            !isNaN(parseFloat(t.value as any))
                            && !isNaN(parseFloat(s.baseValue as any))
                        ) {
                            t.value = (t.value - 1) * parseFloat(s.baseValue as any) + 1;
                        } else if (s.baseValue === null || Number.isNaN(s.baseValue)) {
                            t.value = null;
                        }
                    });
            });
        priceStack
            .filter(s => s && s.valueType === 'percent')
            .forEach(s => {
                priceStack
                    .filter(t => t && t.valueType === 'percent')
                    .filter(this.filterTo(s.to).bind(this))
                    .forEach(t => {
                        if (
                            !isNaN(parseFloat(t.value as any))
                            && !isNaN(parseFloat(s.baseValue as any))
                        ) {
                            t.value =
                                parseFloat(t.value as any) + parseFloat(s.baseValue as any) - 1;
                        } else if (s.baseValue === null || Number.isNaN(s.baseValue)) {
                            t.value = null;
                        }
                    });
            });
        priceStack
            .filter(s => s && s.valueType === 'multiplier')
            .forEach(s => {
                priceStack
                    .filter(t => t && t.valueType === 'value')
                    .filter(this.filterTo(s.to).bind(this))
                    .forEach(t => {
                        if (
                            !isNaN(parseFloat(t.value as any))
                            && !isNaN(parseFloat(s.value as any))
                        ) {
                            priceParts.push({
                                valueType: t.type,
                                percentType: s.type,
                                baseValue: t.value,
                                basePercent: s.value - 1,
                                value: t.value * (s.value - 1),
                                valueId: t.id,
                                percentId: s.id,
                            });
                            t.value = t.value * s.value;
                        } else if (s.value === null || Number.isNaN(s.value)) {
                            t.value = null;
                        }
                    });
            });
        priceStack
            .filter(s => s && s.valueType === 'percent')
            .forEach(s => {
                priceStack
                    .filter(t => t && t.valueType === 'value')
                    .filter(this.filterTo(s.to).bind(this))
                    .forEach(t => {
                        let tValue = t.value;
                        if (this.isMultipliedByBaseValue(s, t)) {
                            tValue = t.baseValue;
                        }
                        if (
                            !isNaN(parseFloat(tValue as any))
                            && !isNaN(parseFloat(t.value as any))
                            && !isNaN(parseFloat(s.value as any))
                        ) {
                            priceParts.push({
                                valueType: t.type,
                                percentType: s.type,
                                baseValue: tValue,
                                basePercent: s.value - 1,
                                value: tValue * (s.value - 1),
                                valueId: t.id,
                                percentId: s.id,
                            });
                            t.value = parseFloat(t.value as any) + tValue * (s.value - 1);
                        } else if (s.value === null || Number.isNaN(s.value)) {
                            t.value = null;
                        }
                    });
            });
        const sum = priceStack
            .filter(s => s && s.valueType === 'value')
            .reduce((prev, cur) => {
                priceParts.push({
                    valueType: cur.type,
                    percentType: null,
                    baseValue: parseFloat(cur.baseValue as any),
                    basePercent: null,
                    value: parseFloat(cur.baseValue as any),
                    valueId: cur.id,
                    percentId: null,
                });
                return prev + parseFloat(cur.value as any);
            }, 0);
        if (priceParts.length === 0) {
            return NaN;
        }

        return sum;
    }

    /**
     * Funkcja do sortowania segmentów ceny.
     *
     * @param {any} seg1 Segment poprzedni
     * @param {any} seg2 Segment następny
     * @returns {number} Porządek
     *
     * @memberOf PriceBaseService
     */
    sort(seg1, seg2) {
        if (seg1.valueType === 'value') {
            return -1;
        } else if (seg2.valueType === 'value') {
            return 1;
        } else {
            return 0;
        }
    }

    /**
     * Rozszerza segment o informację, do czego ma być doliczany.
     *
     * @param {PriceSegment[]} segments Schemat wyceny.
     * @returns
     *
     * @memberOf PriceBaseService
     */
    extendTo(segments: PriceSegment[]) {
        return (data: PriceSegment) => {
            let types: any = '';
            if (data) {
                if (data.valueType === 'percent' || data.valueType === 'multiplier') {
                    if (
                        Common.isArray(this.config().IccConfig.Configurators.price[data.type])
                        || Common.isString(this.config().IccConfig.Configurators.price[data.type])
                    ) {
                        types = core.copy(this.config().IccConfig.Configurators.price[data.type]);
                    }
                    if (Common.isObject(this.config().IccConfig.Configurators.price[data.type])) {
                        const systemSeg = segments.filter(s => s && s.type === 'system');
                        if (
                            systemSeg.length > 0
                            && (Common.isArray(
                                this.config().IccConfig.Configurators.price[data.type][
                                    systemSeg[0].data.type
                                ]
                            )
                                || Common.isString(
                                    this.config().IccConfig.Configurators.price[data.type][
                                        systemSeg[0].data.type
                                    ]
                                ))
                        ) {
                            types = core.copy(
                                this.config().IccConfig.Configurators.price[data.type][
                                    systemSeg[0].data.type
                                ]
                            );
                        }
                    }
                }

                if (Common.isUndefined(data.to)) {
                    data.to = types;
                } else {
                    if (
                        data.to !== 'allValue'
                        && data.to !== 'allSups'
                        && data.to !== 'allValueOrPercentToBase'
                        && Common.isArray(types)
                    ) {
                        let to = data.to;
                        if (!Common.isArray(data.to)) {
                            to = [data.to];
                        }
                        if (Common.isArray(to) && to.length > 0) {
                            const objectTypes = types.filter(type => Common.isObject(type));
                            const matchedToSegment = Common.isArray(to)
                                ? to
                                      .map(t => {
                                          const matchedTypes = objectTypes.filter(type => {
                                              const matchedSegments = segments.filter(
                                                  this.filterTo([type]).bind(this)
                                              );
                                              return (
                                                  matchedSegments.filter(seg => {
                                                      if (
                                                          Common.isString(t)
                                                          && t === '#' + this.getHash(seg)
                                                      ) {
                                                          return true;
                                                      } else if (
                                                          Common.isNumber(t)
                                                          && t === seg.id
                                                      ) {
                                                          return true;
                                                      }
                                                      return false;
                                                  }).length > 0
                                              );
                                          });
                                          if (matchedTypes.length > 0) {
                                              const newT = core.copy(matchedTypes[0]);
                                              newT.to = t;
                                              return newT;
                                          }
                                      })
                                      .filter(t => t)
                                : [];
                            if (objectTypes.length > 0 && matchedToSegment.length > 0) {
                                data.to = matchedToSegment;
                            }
                        }
                    }
                }
            }
        };
    }

    /**
     * Filtruje segmenty
     *
     * @param {any} to Doczego ma być doliczony segment.
     * @returns
     *
     * @memberOf PriceBaseService
     */
    filterTo(to) {
        if (to === 'allValue') {
            return (seg: PriceSegment) => seg.valueType === 'value';
        }
        if (to === 'allValueOrPercentToBase') {
            return (seg: PriceSegment) =>
                seg.type !== 'coupledWindow' && (seg.valueType === 'value' || this.isToBasePercentSegment(seg));
        }
        if (to === 'allSupps') {
            return (seg: PriceSegment) =>
                ['sashes', 'rollerBoxes', 'system', 'margin', 'market', 'marketMargin'].indexOf(
                    seg.type
                ) === -1;
        }
        if (!Array.isArray(to)) {
            to = [to];
        }
        const checkHash = to.some(t => Common.isString(t) && t[0] === '#');
        return (seg: PriceSegment) => {
            let match = false;
            for (const t of to) {
                if (
                    Common.isString(t)
                    && (t === seg.type || (checkHash && t === '#' + this.getHash(seg)))
                ) {
                    match = true;
                    break;
                } else if (Common.isNumber(t) && t === seg.id) {
                    match = true;
                    break;
                } else if (Common.isObject(t)) {
                    let matchValues = true;
                    for (const path in t) {
                        if (path === 'toBase') {
                            continue;
                        }
                        if (path === 'to') {
                            matchValues =
                                matchValues && [seg].some(this.filterTo(t[path]).bind(this));
                            continue;
                        }
                        matchValues = matchValues && core.deepFind(seg, path) === t[path];
                        if (!matchValues) {
                            break;
                        }
                    }
                    if (matchValues) {
                        match = true;
                        break;
                    }
                }
            }
            return match;
        };
    }

    isToBasePercentSegment(segment: PriceSegment) {
        if (segment.valueType !== 'percent') {
            return false;
        }
        let to = segment.to;
        if (!Array.isArray(to)) {
            to = [to];
        }
        return to.some(t => Common.isObject(t) && t.toBase);
    }

    /**
     * Rozszerza segmenty o id.
     *
     * @param {PriceSegment[]} data Schemat wyceny
     * @returns {PriceSegment[]} Schemat wyceny z id.
     *
     * @memberOf PriceBaseService
     */
    extendId(data: PriceSegment[]) {
        if (Common.isArray(data)) {
            data.map(d => {
                if (d) {
                    d.id = this.counter++;
                }
            });
        }
        return data;
    }

    isMultipliedByBaseValue(percentSeg: PriceSegment, valueSeg: PriceSegment) {
        return (
            Common.isArray(percentSeg.to)
            && percentSeg.to
                .filter((t: any) => {
                    return Common.isObject(t);
                })
                .some((t: any) => {
                    let match = false;
                    let matchValues = true;
                    for (const path in t) {
                        if (path === 'toBase') {
                            continue;
                        }
                        if (path === 'to') {
                            continue;
                        }
                        matchValues = matchValues && core.deepFind(valueSeg, path) === t[path];
                        if (!matchValues) {
                            break;
                        }
                    }
                    if (matchValues) {
                        match = Boolean(t.toBase);
                    }
                    return match;
                })
        );
    }

    /**
     * Zwraca hash danego segmentu.
     *
     * @param {PriceSegment} seg Segment
     * @returns {string} Hash
     *
     * @memberOf PriceBaseService
     */
    getHash(seg: PriceSegment) {
        const hashedObj = {
            type: seg.type,
            baseValue: seg.baseValue,
            data: seg.data,
        };
        return JSON.stringify(hashedObj);
    }

    /**
     * Zwraca elementy wyceny ze schematu wyceny.
     *
     * @param {PriceSegment[]} priceStack Schemat wyceny
     * @returns {object} Elementy wyceny
     *
     * @memberOf PriceBaseService
     */
    getPriceElems(priceStack: PriceSegment[]) {
        return priceStack.reduce((prev, seg) => {
            if (Common.isUndefined(prev[seg.type])) {
                prev[seg.type] = [];
            }
            prev[seg.type].push(
                Common.extend(
                    {
                        price: seg.value,
                    },
                    seg.data
                )
            );
            return prev;
        }, {});
    }

    /**
     * Dolicza dopłatę procentową do elementów w PriceElems
     *
     * @param {any} key     Klucz
     * @param {any} system  System
     * @param {any} factors Dopłata
     *
     * @memberOf PriceBaseService
     */
    countPriceElemsFactors(key, system, factors, elems) {
        const constrElems = PriceFunctions.getConstrElems(this.config().IccConfig, system, key);
        constrElems.forEach(c => {
            if (Common.isString(c) && Common.isDefined(elems[c])) {
                core.countFactor(elems[c], factors);
            } else if (Common.isObject(c) && Common.isDefined(elems[c.data])) {
                core.countFactor(elems[c.data], factors);
            }
        });
    }

    countPriceElems(priceStack: PriceSegment[], priceElems, system) {
        const systemFactorsMap = {
            system: 'system',
            color: 'colors',
            fittingsPercent: 'fitting',
            wood: 'wood',
            alushellPercent: 'alushell',
            shape: 'shape',
            steel: 'steel',
            sealColor: 'sealColor',
            siliconeColor: 'siliconeColor',
            frame: 'frame',
            dependencies: 'dependencies',
        };

        const prices = priceStack
            .filter(s => s && s.valueType === 'percent')
            .reduce<any>((prev, s) => {
                if (!prev.hasOwnProperty(s.type)) {
                    prev[s.type] = {
                        sumPrice: 0,
                        sumPriceFactored: 0,
                    };
                }
                priceStack
                    .filter(t => t && t.valueType === 'value')
                    .filter(this.filterTo(s.to).bind(this))
                    .forEach(t => {
                        if (
                            !isNaN(parseFloat(t.value as any))
                            && !isNaN(parseFloat(s.value as any))
                        ) {
                            prev[s.type].sumPrice += parseFloat(t.value as any);
                            prev[s.type].sumPriceFactored += t.value * s.value;
                        } else if (s.value === null || Number.isNaN(s.value)) {
                            prev[s.type].sumPrice = null;
                            prev[s.type].sumPriceFactored = null;
                        }
                    });
                return prev;
            }, {});

        for (const key in prices) {
            if (prices.hasOwnProperty(key) && systemFactorsMap[key]) {
                if (
                    prices[key].sumPrice === 0
                    || prices[key].sumPrice === prices[key].sumPriceFactored
                ) {
                    prices[key] = 1;
                } else {
                    prices[key] = prices[key].sumPriceFactored / prices[key].sumPrice;
                }
                priceElems.systemFactors[systemFactorsMap[key]] = prices[key] * 100 - 100;
                priceElems.factor += prices[key] * 100 - 100;
                this.countPriceElemsFactors(key, system, prices[key], priceElems);
            }
        }
    }
}
