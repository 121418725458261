import { Common } from './Common';
import { core } from './helpers';
import { currencyExchange } from '@icc/helpers';

/**
 * Usługa liczenia ceny
 *
 * @export
 * @class PossitionDetailedSummary
 */
export class PositionDetailedSummaryService {
    /**
     * Creates an instance of PriceService.
     *
     * @param {ng.ui.bootstrap.IModalService} $uibModal $uibModal
     * @param {any} ConfigurationsService ConfigurationsService
     * @param {PriceBaseService} PriceBaseService PriceBaseService
     *
     * @memberOf PriceService
     */
    constructor() {}

    /**
     * Szczegoly do podsumowania
     * @param  {object} summary  Podsumowanie
     * @param  {object} position Pozycja oferty
     * @return {object}          Szczegoly podsumowania
     */
    static detailedSummary(position, IccConfig, currency, summary = null) {
        const details = position.details;
        let quantity = position.quantity;

        const singlePosition = summary === null;
        if (summary === null) {
            quantity = 1;
            summary = {
                client: {
                    additionals: {
                        product: 0,
                        service: 0,
                    },
                    others: {
                        product: 0,
                        service: 0,
                    },
                    components: {
                        glass: 0,
                        glassPromotions: 0,
                        fitting: 0,
                        addons: 0,
                        base: 0,
                        basePromotions: 0,
                        roller: 0,
                        mosquito: 0,
                        colorCost: 0,
                    },
                },
                dealer: {
                    additionals: {
                        product: 0,
                        service: 0,
                    },
                    others: {
                        product: 0,
                        service: 0,
                    },
                    components: {
                        glass: 0,
                        glassPromotions: 0,
                        fitting: 0,
                        addons: 0,
                        base: 0,
                        basePromotions: 0,
                        roller: 0,
                        mosquito: 0,
                        colorCost: 0,
                    },
                },
                rollerType: 'rollerSupps',
                promotions: {
                    raster: [],
                    glass: [],
                    rollerShutterRaster: [],
                },
            };
        }

        if (Common.isDefined(details) && details) {
            if (position.confType === 'additional') {
                if (Common.isDefined(summary.client.additionals[details.goodType])) {
                    summary.client.additionals[details.goodType] +=
                        position.client_price_before_discount * quantity;
                }
            } else if (position.confType === 'other') {
                if (Common.isDefined(summary.client.others[details.goodType])) {
                    summary.client.others[details.goodType] +=
                        position.client_price_before_discount * quantity;
                    summary.dealer.others[details.goodType] +=
                        position.dealer_price_before_discount * quantity;
                }
            } else if (position.confType === 'custom') {
                summary.client.components.base += position.client_price_before_discount * quantity;
                summary.dealer.components.base += position.dealer_price_before_discount * quantity;
            } else if (position.confType === 'colors_cost') {
                summary.client.components.colorCost +=
                    position.client_price_before_discount * quantity;
                summary.dealer.components.colorCost +=
                    position.dealer_price_before_discount * quantity;
            } else {
                const summaryParts = core.copy(IccConfig.Offer.summary);
                if (details.type === 'roller_shutter' || details.type === 'external_blind') {
                    summaryParts.base.push(...summaryParts.roller);
                    summaryParts.roller = [];
                }

                if (Common.isDefined(details.priceParts)) {
                    details.priceParts
                        .filter(part => part.value != 0)
                        .forEach(part => {
                            for (const i in summaryParts) {
                                if (
                                    Common.isArray(summaryParts[i])
                                    && this.isInSummaryParts(part, summaryParts[i])
                                ) {
                                    summary.client.components[i] += currencyExchange(part.value * quantity, currency, true, false);
                                }
                            }
                        });
                    details.pricePartsNoMargin
                        .filter(part => part.value != 0)
                        .forEach(part => {
                            for (const i in summaryParts) {
                                if (
                                    Common.isArray(summaryParts[i])
                                    && this.isInSummaryParts(part, summaryParts[i])
                                ) {
                                    summary.dealer.components[i] += currencyExchange(part.value * quantity, currency, true, false);
                                }
                            }
                        });
                    if (
                        this.isInSummaryParts(
                            { valueType: 'rollerBoxes', percentType: null },
                            summaryParts.roller
                        )
                    ) {
                        summary.rollerType = 'roller';
                    }

                    if (!singlePosition) {
                        for (const i in summaryParts) {
                            if (Common.isArray(summaryParts[i])) {
                                summary.client.components[i] = core.round(summary.client.components[i]);
                                summary.dealer.components[i] = core.round(summary.dealer.components[i]);
                            }
                        }
                    }

                    summary.promotions = this.summaryPromotions(
                        'client',
                        details,
                        currency,
                        quantity,
                        summary.promotions,
                    );
                    summary.promotions = this.summaryPromotions(
                        'dealer',
                        details,
                        currency,
                        quantity,
                        summary.promotions,
                    );
                }
            }
        }

        return summary;
    }

    static isInSummaryParts(part, summaryParts) {
        return summaryParts.some(
            s =>
                (s[0] == part.valueType
                    || s[0] == '*'
                    || (Common.isArray(s[0]) && s[0].indexOf(part.valueType) > -1))
                && (s[1] == part.percentType
                    || s[1] == '*'
                    || (Common.isArray(s[1]) && s[1].indexOf(part.percentType) > -1))
        );
    }

    /**
     * Sumuje rabaty dealera
     * @param  {array} discounts Tabliza rabatów
     * @return {number}          Suma wartości
     */
    static sumDiscounts(discounts, priceType = 'dealer') {
        if (!priceType || priceType === 'valuation') {
            return 0;
        }
        if (!Array.isArray(discounts)) {
            return 0;
        }
        return discounts.reduce((prev, e) => (e[priceType] ? prev + e[priceType].value : prev), 0);
    }

    static summaryPromotions(priceType = 'dealer', details, currency, quantity, promotions = {
        raster: [],
        glass: [],
        rollerShutterRaster: [],
    }) {
        const priceParts = priceType === 'dealer' ? details.pricePartsNoMargin : details.priceParts;
        const priceSegments = priceType === 'dealer' ? details.priceSegmentsNoMargin : details.priceSegments;

        priceParts.filter(part => part.value != 0).forEach(part => {
            let promotionsArray = null;
            if (part.valueType === 'promotionFilling' || part.percentType === 'promotionFilling') {
                promotionsArray = promotions.glass;

            } else if (part.percentType === 'promotionRaster') {
                promotionsArray = promotions.raster;
            } else if (part.percentType === 'promotionRollerShutterRaster') {
                promotionsArray = promotions.rollerShutterRaster;
            }

            if (promotionsArray) {
                const segment = priceSegments.find(el => (el.type === 'promotionFilling' || el.type === 'promotionRaster' || el.type === 'promotionRollerShutterRaster') && (el.id === part.valueId || el.id === part.percentId));
                if (segment) {
                    const promotion = promotionsArray.find(el => el.id === segment.data.promotionId);
                    if (promotion) {
                        promotion[priceType] += currencyExchange(part.value * quantity, currency, true, false);
                    } else {
                        promotionsArray.push({
                            id: segment.data.promotionId,
                            name: segment.data.name,
                            [priceType]: currencyExchange(part.value * quantity, currency, true, false),
                            percent: segment.data?.promotionType === 'percent' ? segment.data?.promotionValue: null,
                        });
                    }
                }
            }
        });

        return promotions;
    }
}
