import { Injectable, Inject } from '@angular/core';
import { SideColorsLegacy } from '@icc/common/configurations/parts/common';
import { Common } from '@icc/common/Common';
import { core } from '@icc/common/helpers';
import { ColorsDefaultsService } from '@icc/common/colors/colors-defaults.service';
import { ConfiguratorsDataService } from '@icc/common/configurators/configurators-data.service';
import { CuttingStockService } from './cutting-stock.service';
import * as camelCase from 'camelcase';
import { currencyExchange } from '@icc/helpers';
import { APP_CONFIG, AppConfigFactory, TranslateService } from '@icc/common';

@Injectable()
export class ColorsPositionService {
    private shutterElementsDictionary = {
        box: this.translateService.instant('ROLLERSHUTTER|Skrzynka'),
        guide_rail:  this.translateService.instant('ROLLERSHUTTER|Prowadnica'),
        revision: this.translateService.instant('ROLLERSHUTTER|Klapa rewizyjna'),
        box_inner: this.translateService.instant('ROLLERSHUTTER|Skrzynka od wewnątrz'),
        box_guide_outer: this.config().IccConfig.Configurators.roller_shutter.boxColorGuideColor
            ?  this.translateService.instant('ROLLERSHUTTER|Skrzynka od zewnątrz') :  this.translateService.instant('ROLLERSHUTTER|Skrzynka i prowadnice od zewnątrz'),
        profile: this.translateService.instant('ROLLERSHUTTER|Pancerz'),
        endslat:  this.translateService.instant('ROLLERSHUTTER|Listwa końcowa'),
        box_side_surface: this.translateService.instant('ROLLERSHUTTER|Boczki skrzynki'),
    };

    constructor(
        private colorsDefaultsService: ColorsDefaultsService,
        private configuratorsDataService: ConfiguratorsDataService,
        private cuttingStockService: CuttingStockService,
        private translateService: TranslateService,
        @Inject(APP_CONFIG) private config: AppConfigFactory
    ) {}

    groupPositionsByColors(positions, offer) {
        const groups = {};
        positions
            .filter(position => ['window', 'hs', 'door', 'folding_door', 'sliding_door'].indexOf(position.doc.confType) > -1)
            .forEach(position => {
                const groupCode = this.getColorCode(
                    position.doc.details.type,
                    position.doc.details.system,
                    position.doc.details.colors?.frame,
                    position.doc.details.wood,
                );
                if (!groups[groupCode]) {
                    if (Common.isString(position.doc.details) && position.doc.details !== '') {
                        position.doc.details = core.parseJson(position.doc.details);
                    }

                    groups[groupCode] = {
                        system: position.doc.details.dictionary.systems?.[position.doc.details.system],
                        colors: position.doc.details.colors,
                        colorsSashExt: position.doc.details.colorsSashExt,
                        colorsDictionary: position.doc.details.dictionary.colors,
                        constructColorsDictionary: position.doc.details.dictionary.constrColors,
                        wood: position.doc.details.wood,
                        alushell: {
                            has: position.doc.details.hasAlushell,
                            type: position.doc.details.alushellType,
                        },
                        positionsPrice: position.doc.client_price * position.doc.quantity,
                        positionsPriceBeforeDiscount: position.doc.client_price_before_discount * position.doc.quantity,
                        positionsDealerPriceBeforeDiscount: position.doc.dealer_price_before_discount * position.doc.quantity,
                        factor: 0,
                        price: 0,
                        dealerPrice: 0
                    };
                } else {
                    groups[groupCode].positionsPrice +=
                        position.doc.client_price * position.doc.quantity;
                    groups[groupCode].positionsPriceBeforeDiscount +=
                        position.doc.client_price_before_discount * position.doc.quantity;
                    groups[groupCode].positionsDealerPriceBeforeDiscount +=
                        position.doc.dealer_price_before_discount * position.doc.quantity;
                }
            });
        positions
            .filter(position => ['roller_shutter'].indexOf(position.doc.confType) > -1 || position.doc.details.rollerShutter?.colors)
            .forEach(position => {
                // eslint-disable-next-line guard-for-in
                for (const element in position.doc.details.rollerShutter.colors) {
                    if (!position.doc.details.rollerShutter.colors[element]?.id) {
                        continue;
                    }
                    const groupCode = `roller_shutter_${position.doc.details.rollerShutter.colors[element].id}_${element}`;
                    let elementId = null;
                    const elementSnakeCase = core.toSnakeCase(element);
                    let elementName = this.shutterElementsDictionary[elementSnakeCase];

                    if (element === 'endslat') {
                        elementId = +position.doc.details.rollerShutter.slats[0].id;
                        elementName = `${elementName}: ${position.doc.details.rollerShutter.slats[0].name}`;
                    } else if (element === 'guideRail') {
                        elementId = +position.doc.details.rollerShutter.guideRails[0].id;
                        elementName = `${elementName}: ${position.doc.details.rollerShutter.guideRails[0].name}`;
                    } else if (element === 'profile') {
                        elementId = +position.doc.details.rollerShutter.profile.id;
                        elementName = `${elementName}: ${position.doc.details.rollerShutter.profile.name}`;
                    }
                    if (!groups[groupCode]) {
                        if (Common.isString(position.doc.details) && position.doc.details !== '') {
                            position.doc.details = core.parseJson(position.doc.details);
                        }

                        groups[groupCode] = {
                            type: 'roller_shutter',
                            system: position.doc.details.rollerShutter.system,
                            color: position.doc.details.rollerShutter.colors[element],
                            elements: [{
                                element: elementSnakeCase,
                                elementId,
                                elementName,
                            }],
                            positionsPrice: position.doc.client_price * position.doc.quantity,
                            positionsPriceBeforeDiscount: position.doc.client_price_before_discount * position.doc.quantity,
                            positionsDealerPriceBeforeDiscount: position.doc.dealer_price_before_discount * position.doc.quantity,
                            factor: 0,
                            price: 0,
                            dealerPrice: 0
                        };
                    } else {
                        groups[groupCode].positionsPrice +=
                            position.doc.client_price * position.doc.quantity;
                        groups[groupCode].positionsPriceBeforeDiscount +=
                            position.doc.client_price_before_discount * position.doc.quantity;
                        groups[groupCode].positionsDealerPriceBeforeDiscount +=
                            position.doc.dealer_price_before_discount * position.doc.quantity;
                        if (!groups[groupCode].elements.some(el => el.element === elementSnakeCase && el.elementId === elementId)) {
                            groups[groupCode].elements.push({
                                element: elementSnakeCase,
                                elementId,
                                elementName,
                            });
                        }
                    }
                }
            });
        const colorPriceGroups = Object.keys(groups)
            .map(groupCode => groups[groupCode])
            .map(group => {
                let factor;
                if (group.colorsDictionary) {
                    factor = this.getColorFactor({
                        colorGroups: this.configuratorsDataService.data.windowColorGroups,
                        colors: group.colors,
                        colorsDictionary: group.colorsDictionary,
                        constructColorsDictionary: group.constructColorsDictionary,
                        system: group.system,
                        wood: group.wood,
                        price: group.positionsPrice,
                    });
                } else {
                    factor = this.getShutterColorFactor({
                        colorGroups: this.configuratorsDataService.data.windowColorGroups,
                        color: group.color,
                        elements: group.elements,
                        system: group.system,
                        price: group.positionsPrice,
                    });

                }
                group.factor = factor;
                group.price = group.positionsPriceBeforeDiscount * factor.percent / 100 + currencyExchange(factor.price, offer.currency);
                group.dealerPrice = group.positionsDealerPriceBeforeDiscount * factor.percent / 100 + currencyExchange(factor.price, offer.currency);
                return group;
            })
            .filter(group => group.price > 0);
        return colorPriceGroups;
    }

    groupPositionsByProfilesInColors(positions) {
        const groups = {};
        positions
            .filter(position => ['window', 'hs', 'door', 'folding_door', 'sliding_door'].indexOf(position.doc.confType) > -1)
            .forEach(position => {
                if (Common.isString(position.doc.details) && position.doc.details !== '') {
                    position.doc.details = core.parseJson(position.doc.details);
                }
                if (position.doc.details.usedProfilesSegments) {
                    for (const profileId in position.doc.details.usedProfilesSegments) {
                        if (position.doc.details.usedProfilesSegments.hasOwnProperty(profileId)) {
                            const segments = position.doc.details.usedProfilesSegments[profileId];
                            segments.forEach(segment => {
                                const groupCode = this.getProfileInColorCode(
                                    profileId,
                                    segment.color
                                );
                                if (!groups[groupCode]) {
                                    const profilePriceData = this.getProfilePrice(
                                        profileId,
                                        this.configuratorsDataService.data.profilesPrices,
                                        segment.color
                                    );
                                    if (profilePriceData && profilePriceData.length) {
                                        const profilePrice = Math.min(
                                            ...profilePriceData.map(p => p.price_length)
                                        );
                                        const profileSettlementLength = Math.min(
                                            ...profilePriceData.map(p => p.settlement_length || 0)
                                        );
                                        if (profileSettlementLength && profilePrice) {
                                            groups[groupCode] = {
                                                profile: position.doc.details.dictionary?.profiles[profileId],
                                                colors: segment.color,
                                                usedLength: segment.length,
                                                price: 0,
                                                waste: 0,
                                                sticksCount: 0,
                                                profilePrice,
                                                segments: Array(position.doc.quantity).fill(
                                                    segment.length
                                                ),
                                                settlementLength: profileSettlementLength,
                                            };
                                        }
                                    }
                                } else {
                                    groups[groupCode].usedLength +=
                                        segment.length * position.doc.quantity;
                                    groups[groupCode].segments.push(
                                        ...Array(position.doc.quantity).fill(segment.length)
                                    );
                                }
                            });
                        }
                    }
                }
            });
        const colorPriceGroups = Object.keys(groups)
            .map(groupCode => groups[groupCode])
            .map(group => {
                const { waste, stickCount } = this.cuttingStockService.fit(
                    group.segments,
                    group.settlementLength
                );
                group.waste = waste;
                group.sticksCount = stickCount;
                group.price = (group.profilePrice * waste) / 1000;
                return group;
            });

        return colorPriceGroups;
    }

    getProfileInColorCode(profileId, colors) {
        let code = profileId.toString();
        ['outer', 'core', 'inner'].forEach(side => {
            code += `_${colors[side] ? colors[side].id : 0}_0`;
        });
        return code;
    }

    getColorCode(type: string, system: number, colors, wood: number) {
        let code = `${type}_${system}`;
        ['outer', 'core', 'inner'].forEach(side => {
            code += `_${colors[side] ? colors[side].id : 0}_0`;
        });
        code += `_${wood ? wood : 0}`;
        return code;
    }

    /**
     * Zwraca najwyższą dopłatę procentową dla koloru.
     * @param  {object} colorGroups Grupy kolorów
     * @param  {object} color       Kolor
     * @param  {string} prop        Nazwa pola zawierającego dopłatę.
     * @return {number}             Dopłata procentowa dla koloru
     */
    getMaxColorGroupFactor({
        colorGroups,
        color,
        prop,
        system,
        wood,
        price,
    }: {
        colorGroups;
        color;
        prop;
        system;
        wood;
        price;
    }) {
        let colorFactorExtra = null;
        let colorFactorExtraPrice = null;
        if (!Common.isNumber(price) || !system) {
            return {
                colorFactorExtra: NaN,
                colorFactorExtraPrice: NaN,
            };
        }
        if (!colorGroups) {
            colorGroups = [];
        }
        colorGroups = colorGroups.filter(
            el =>
                Common.isArray(el.systems)
                && el.systems.map(Number).indexOf(Number(system.id)) > -1
                && el.target.indexOf('price') > -1
                && (system.type !== 'wood'
                    || (Common.isArray(el.woodTypes)
                        && el.woodTypes.map(Number).indexOf(Number(wood)) > -1))
        );
        if (color && Common.isArray(color.groups)) {
            for (let i = 0; i < color.groups.length; i++) {
                let group = colorGroups.find(el => Number(el.id) === Number(color.groups[i]));
                if (color.groups[i] === 'RAL') {
                    group = this.getRALGroup(colorGroups);
                }
                if (Common.isDefined(group)) {
                    const customColorFactor = null;
                    if (Common.isDefined(group.offer_price_ranges)) {
                        const offerPriceRanges =
                            (group.offer_price_ranges && core.parseJson(group.offer_price_ranges))
                            || [];
                        const colorSashFactors = offerPriceRanges
                            .filter(
                                p =>
                                    Number(p.from) <= parseFloat(price)
                                    && Number(p.to) >= parseFloat(price)
                            );

                        const colorSashFactor = colorSashFactors.reduce((prev, cur) => prev < cur[camelCase(prop)] ? cur[camelCase(prop)] : prev, 0);
                        const colorSashExtraPrice = colorSashFactors.reduce((prev, cur) => prev < cur[camelCase(prop)+'Price'] ? cur[camelCase(prop)+'Price'] : prev, 0);
                        if (colorSashFactor > colorFactorExtra || colorFactorExtra == null) {
                            colorFactorExtra = colorSashFactor;
                        }
                        if (colorSashExtraPrice > colorFactorExtraPrice || colorFactorExtraPrice == null) {
                            colorFactorExtraPrice = colorSashExtraPrice;
                        }
                    }
                }
            }
        }
        return {
            colorFactorExtra: colorFactorExtra == null ? NaN : colorFactorExtra,
            colorFactorExtraPrice: colorFactorExtraPrice == null ? NaN : colorFactorExtraPrice,
        };
    }


    getMaxShutterColorGroupFactor({
        colorGroups,
        color,
        element,
        system,
        price,
    }: {
        colorGroups;
        color;
        element,
        system;
        price;
    }) {
        let colorFactorExtra = null;
        let colorFactorExtraPrice = null;
        if (!Common.isNumber(price) || !system) {
            return {
                colorFactorExtra: NaN,
                colorFactorExtraPrice: NaN,
            };
        }
        if (!colorGroups) {
            colorGroups = [];
        }
        colorGroups = colorGroups.filter(
            el =>
                Common.isArray(el.roller_systems)
                && el.roller_systems.map(Number).indexOf(Number(system.id)) > -1
                && el.target.indexOf('price_roller') > -1
                && Common.isArray(el.roller_element)
                && el.roller_element.indexOf(element.element) > -1
                && (!element.elementId || el.profiles.indexOf(element.elementId + '') > -1)
        );
        if (color && Common.isArray(color.groups)) {
            for (let i = 0; i < color.groups.length; i++) {
                let group = colorGroups.find(el => Number(el.id) === Number(color.groups[i]));
                if (color.groups[i] === 'RAL') {
                    group = this.getRALGroup(colorGroups);
                }
                if (Common.isDefined(group)) {
                    const colorSashFactor = 0;
                    const colorSashExtraPrice = +group.rs_price_offer;
                    if (colorSashFactor > colorFactorExtra || colorFactorExtra == null) {
                        colorFactorExtra = colorSashFactor;
                    }
                    if (colorSashExtraPrice > colorFactorExtraPrice || colorFactorExtraPrice == null) {
                        colorFactorExtraPrice = colorSashExtraPrice;
                    }
                }
            }
        }
        return {
            colorFactorExtra: colorFactorExtra == null ? NaN : colorFactorExtra,
            colorFactorExtraPrice: colorFactorExtraPrice == null ? NaN : colorFactorExtraPrice,
        };
    }

    /**
     * Zwraca dopłatę procentową za RAL.
     * @param  {array}  colorGroups   Grupy kolorów
     * @return {number}               Cena po dopłatach
     */
    getRALGroup(colorGroups) {
        const colorGroupsArray: any[] = core.objToArray(colorGroups);
        const RALGroup = core.fIdO(colorGroupsArray, 'ral', 'code');
        return RALGroup;
    }

    getColorFactor({
        colorGroups,
        colors,
        colorsDictionary,
        constructColorsDictionary,
        system,
        wood,
        price,
    }: {
        colorGroups;
        colors;
        colorsDictionary;
        constructColorsDictionary;
        system;
        wood;
        price;
    }) {
        let colorFactor = 0;
        let colorFactorPrice = 0;
        let prop = 'price_factor_duo';

        const colorsObj = {
            outer: constructColorsDictionary[colors.frame?.outer?.id],
            inner: constructColorsDictionary[colors.frame?.inner?.id],
            core: colorsDictionary[colors.frame?.core?.id],
        };

        if (colorsObj.outer?.id === colorsObj?.inner?.id) {
            prop = 'price_factor_both';
        } else if (!colorsObj.outer?.id || colorsObj.outer?.isCore) {
            prop = 'price_factor_in';
        }  else if (!colorsObj?.inner?.id || colorsObj?.inner?.isCore) {
            prop = 'price_factor_out';
        }

        let side = 'inner';
        if (!colorsObj.outer?.id && !colorsObj?.inner?.id || colorsObj.outer?.isCore && colorsObj?.inner?.isCore) {
            side = 'core';
        } else if (!colorsObj?.inner?.id || colorsObj?.inner?.isCore) {
            side = 'outer';
        }

        const colorFactors = this.getMaxColorGroupFactor({
            colorGroups,
            color: colorsObj[side],
            prop,
            system,
            wood,
            price,
        });
        colorFactor = colorFactors.colorFactorExtra;
        colorFactorPrice = colorFactors.colorFactorExtraPrice;
        if (colorsObj.outer?.id &&
            colorsObj.inner?.id &&
            !colorsObj.outer?.isCore &&
            !colorsObj.inner?.isCore &&
            colorsObj.outer?.id !== colorsObj?.inner?.id
        ) {
            const colorFactorsOut = this.getMaxColorGroupFactor({
                colorGroups,
                color: colorsObj.outer,
                prop: 'price_factor_duo',
                system,
                wood,
                price,
            });
            const colorFactorOut = colorFactorsOut.colorFactorExtra;
            const colorFactorOutPrice = colorFactorsOut.colorFactorExtraPrice;
            colorFactor =
                colorFactorOut > colorFactor || isNaN(colorFactorOut)
                    ? colorFactorOut
                    : colorFactor;
            colorFactorPrice =
                colorFactorOutPrice > colorFactorPrice || isNaN(colorFactorOutPrice)
                    ? colorFactorOutPrice
                    : colorFactorPrice;
        }
        return { percent: colorFactor, price: colorFactorPrice };
    }

    getShutterColorFactor({
        colorGroups,
        color,
        elements,
        system,
        price,
    }: {
        colorGroups;
        color;
        elements
        system;
        price;
    }) {
        let colorFactor = 0;
        let colorFactorPrice = 0;

        elements.forEach(element => {
            const colorFactors = this.getMaxShutterColorGroupFactor({
                colorGroups,
                color,
                element,
                system,
                price,
            });

            if (colorFactors.colorFactorExtra > colorFactor) {
                colorFactor = colorFactors.colorFactorExtra;
            }
            if (colorFactors.colorFactorExtraPrice > colorFactorPrice) {
                colorFactorPrice = colorFactors.colorFactorExtraPrice;
            }
        });

        return { percent: colorFactor, price: colorFactorPrice };
    }

    getProfilePrice(profileId, prices, colors) {
        if (
            !prices
            || !prices[profileId]
            || !prices[profileId].default
            || !prices[profileId].default
            || !colors
        ) {
            return null;
        }
        const profilePrices = prices[profileId].default;
        let colorGroups = [];
        let colorGroupsOut = [];
        let side = 'double';

        if (colors.inner?.groups && (!colors.outer?.groups || colors.outer?.isCore)) {
            colorGroups = colors.inner.groups.map(Number);
            side = 'single';
        } else if (colors.outer?.groups && (!colors.inner?.groups || colors.inner?.isCore)) {
            colorGroups = colors.outer.groups.map(Number);
            side = 'single';
        } else if (colors.inner?.groups && colors.outer?.groups && colors.outer.id === colors.outer.id) {
            colorGroups = colors.inner.groups.map(Number);
        } else if (colors.inner?.groups && colors.outer?.groups && colors.outer.id !== colors.outer.id) {
            colorGroups = colors.inner.groups.map(Number);
            colorGroupsOut = colors.outer.groups.map(Number);
            side = 'bicolor';
        } else {
            return null;
        }

        const profilePrice = Object.keys(profilePrices)
            .map(type => profilePrices[type])
            .map(pricesType =>
                pricesType
                    .map((p, index) => {
                        p.id = index;
                        return p;
                    })
                    .find(
                        p =>
                            colorGroups.indexOf(Number(p.colorGroup)) > -1
                            && (side !== 'bicolor'
                                || (side === 'bicolor'
                                    && colorGroupsOut.indexOf(Number(p.colorGroupOut)) > -1))
                            && p.side === side
                    )
            )
            .filter(el => el);
        if (profilePrice) {
            return profilePrice;
        }
        return null;
    }
}
